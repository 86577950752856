import React, { useState, useEffect } from "react";
import { Link, Redirect, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";

import { getVersion } from "../utils/common";
import logowhite from "../assets/logoblack.png";
import { useAuthLoginMutation } from "../store/services/authService";

import {
  setUserInfo,
  setUserId,
  setUserRole,
  setUserToken,
  setUserOrgId,
  setOrgList,
} from "../store/reducers/authReducer";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const handleInputs = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const [login, response] = useAuthLoginMutation();
  console.log("login response", response);
  const [errors, setErrors] = useState(
    response?.error?.data ? response.error.data.message : null
  );
  // const errors = response?.error?.data ? response.error.data.message : null;

  const adminLoginFuction = (e) => {
    e.preventDefault();
    login(state);
  };

  useEffect(() => {
    if (response.isSuccess) {
      if (response?.data?.data.isRevoked) {
        setErrors("You can not login please contact with admin!");
      } else {
        localStorage.setItem("user-token", response?.data?.data.token);
        localStorage.setItem(
          "orgList",
          JSON.stringify(response?.data?.data?.organizations)
        );
        dispatch(setUserToken(response?.data?.data.token));
        dispatch(setOrgList(response?.data?.data?.organizations));

        if (response?.data?.data?.organizations) {
          navigate("/selectOrg");
        }
      }
      // localStorage.setItem("user-role", response?.data?.data.roles[0]);
      // localStorage.setItem("user-uId", response?.data?.data.uId);
      // localStorage.setItem("user-userId", response?.data?.data.userId);
      // localStorage.setItem(
      //   "user-orgid",
      //   response?.data?.data.organizations[0].corpId
      // );
      // dispatch(setUserOrgId(response?.data?.data.organizations[0].corpId));
      // dispatch(setUserInfo(response?.data?.data));
      // dispatch(setUserId(response?.data?.data.uId));
      // dispatch(setUserRole(response?.data?.data.roles[0]));
    }
  }, [response.isSuccess]);

  useEffect(() => {
    if (response.isError) {
      setErrors(response?.error?.data.message);
    }
  }, [response?.isError]);

  return (
    <form
      onSubmit={adminLoginFuction}
      className="login-p flex flex-col aic w-full"
    >
      <div className="wrapper wrapWidth flex flex-col items-center">
        <div className="logo">
          <img src={logowhite} className="img" width={85} height={85} />
          <span
            style={{ fontSize: "12px", fontWeight: "600", marginTop: "5px" }}
          >
            Version {getVersion()}{" "}
          </span>
        </div>
        <div className="form-block flex flex-col bg-white p-6 rounded-lg">
          <div className="title font s32 b5 c000">Login to your account</div>
          {errors !== null && (
            <div className="error-message flex bg-red-100 text-red-700 p-3  mb-2 rounded-sm text-sm font-medium">
              {errors}
            </div>
          )}
          <div className="feild flex flex-col mb-5">
            <div className="lbl font s14 c68">Email</div>
            <input
              type="email"
              name="email"
              id="email"
              onChange={handleInputs}
              value={state.email}
              className="iput font s14 c000"
            />
          </div>
          <div className="feild flex flex-col mb-2">
            <div className="lbl font s14 c68">Password</div>
            <input
              type="password"
              name="password"
              onChange={handleInputs}
              value={state.password}
              className="iput font s14 c000"
            />
          </div>
          <div className="row flex items-center justify-between mb-5">
            <div className="item-left flex aic">
              <input
                type="checkbox"
                className="check-box h-4 w-4 cursor-pointer mr-4"
              />
              <div className="checkbox-lbl">Remember</div>
            </div>
            <div className="item-right flex">
              <div
                className="checkbox-lbl cursor-pointer hover:underline"
                onClick={() =>
                  window.open(`${process.env.REACT_APP_URL}/forgetpassword`)
                }
              >
                Forgot password?
              </div>
            </div>
          </div>
          <div className="feild btn flex flex-col">
            <input
              type="submit"
              className="btn button"
              disabled={response.isLoading ? true : false}
              value={response.isLoading ? "Loading..." : "Login"}
            />
            {/* 
            Login
          </input> */}
          </div>
          {/* <Link to="/register" className="font s14 c68 hover:underline">
            Register
          </Link> */}
        </div>
      </div>
    </form>
  );
};

export default Login;
