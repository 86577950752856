import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TypeWriterEffect from "react-typewriter-effect";
import { AppDrawer } from "@bsilobook/component";

import { DoubleArrowIcon, ArrowDownIcon, MenuIcon } from "../../assets";
import AppIcon from "../../images/app.svg";

import { logout } from "../../store/reducers/authReducer";
import { useGetAllAppsQuery } from "../../store/services/usersService";
// import { useFetchInfoQuery } from "../../store/services/authService";
import {
  setUserInfo,
  setUserId,
  setUserRole,
  setUserToken,
  setUserOrgId,
} from "../../store/reducers/authReducer";

const Header = ({ setUser, userData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { userRole, userToken, userOrgId, userId } = useSelector(
    (state) => state.authReducer
  );

  const [apps, setApps] = useState([]);

  const { data: resApp, isFetching: resAppFetching } =
    useGetAllAppsQuery(userId);

  useEffect(() => {
    if (resApp?.data) {
      const mapRes = resApp?.data.map((x) => ({
        code: x.code,
        label: x.name,
        link: x.link,
        image: x.image ? x.image : AppIcon,
      }));
      setApps(mapRes);
    }
  }, [resApp]);

  const [openMenu, setOpenMenu] = useState(false);
  const getCurrentTitle = () => {
    switch (location.pathname) {
      case "/":
      default:
        return "Dash Board";
      case "/general":
        return "Company General Information";
      case "/update-general":
        return "Update General Information";
      case "/department":
        return "Departments";
      case "/departmen-details":
        return "Departmen Details";
      case "/new-department":
        return "New Department";
      case "/bank-account":
        return "Company Bank Accounts";
      case "/new-bank-account":
        return "New Bank Account";
      case "/update-bank-account":
        return "Update Bank Accounts";
      case "/cpf-account":
        return "Company CPF Accounts";
      case "/new-cpf-account":
        return "New CPF Accounts";
      case "/update-cpf-account":
        return "Update CPF Accounts";
      case "/component":
        return "Components Information";
      case "/new-component":
        return "New Component";
      case "/users":
        return "Users";
      case "/new-user":
        return "User Information";
      // case "/claims-list":
      //   return "Pending Claims List";
      case "/claims-list":
        return "Pending Claims List";
      case "/claims-details/*":
        return "Claims Details";
      case "/cpf-report":
        return "CPF Report";
      case "/edit-claim":
        return "Edit Claim Details";
      case "/payroll":
        return "Payroll Process List";
      case "/new-payroll":
        return "New Payroll";
    }
  };

  const userLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  useEffect(() => {
    document.addEventListener("click", () => {
      setOpenMenu(false);
    });
  }, []);

  const switchUser = () => {
    if (userData?.data?.roles?.length >= 2) {
      localStorage.setItem("user-role", userData?.data?.roles[1]);
      dispatch(setUserRole(userData?.data?.roles[1]));
      navigate("/");
    } else {
      localStorage.setItem("user-role", userData?.data?.roles[0]);
      dispatch(setUserRole(userData?.data?.roles[0]));
      navigate("/");
    }
  };

  return (
    <div className="header-camp flex">
      <div className="wrapWidth wrap flex items-center">
        <div className="left flex items-center flex-1">
          <div className="Title text-2xl font-semibold">
            {getCurrentTitle()}
          </div>
        </div>
        <div className="right flex items-center justify-end flex-1">
          {userData?.data?.roles?.length > 1 ? (
            <div className="btn button" onClick={(e) => switchUser()}>
              <div className="lbl text-sm" style={{ margin: "auto" }}>
                Switch to user
              </div>
              <div className="icon">
                <DoubleArrowIcon />
              </div>
            </div>
          ) : (
            <div
              className="btn button"
              style={{
                background: "#d4d4d4",
                cursor: "default",
                border: "1px #d4d4d4 solid",
              }}
            >
              <div
                className="lbl text-sm"
                style={{
                  margin: "auto",
                  background: "#d4d4d4",
                  cursor: "default",
                }}
              >
                Switch to user
              </div>
              <div className="icon">
                <DoubleArrowIcon />
              </div>
            </div>
          )}
          <div className="icon-menu flex aic jc ml-5 mr-5 cursor-pointer">
            <AppDrawer
              apps={apps}
              token={userToken}
              orgId={userOrgId}
              width="40px"
              height="40px"
              popupRight="20px"
              popupTop="70px"
              bgColor="#ddd"
            />
          </div>
          <div className="about-user flex items-center">
            <img
              src={
                userData?.data
                  ? userData?.data?.profile
                  : "./images/blank_profile_pic.png"
              }
              className="h-10 w-10 rounded-full img"
            />
            {userData?.profile}
            <div className="drop-menu flex">
              <div className="user-info flex flex-col">
                <div className="name">
                  {userData?.data ? (
                    userData?.data.userName
                  ) : (
                    <TypeWriterEffect
                      textStyle={{
                        fontFamily: "inherit",
                        color: "black",
                        fontWeight: "400",
                      }}
                      startDelay={100}
                      cursorColor="black"
                      text="Loading..."
                      typeSpeed={150}
                      loop={true}
                    />
                  )}
                </div>
                <div className="desi">
                  {userData?.data ? (
                    userData?.data.roles[0]
                  ) : (
                    <TypeWriterEffect
                      textStyle={{
                        fontFamily: "inherit",
                        color: "black",
                        fontWeight: "400",
                      }}
                      startDelay={100}
                      cursorColor="black"
                      text="Loading..."
                      typeSpeed={150}
                      loop={true}
                    />
                  )}
                </div>
              </div>
            </div>
            <div
              className="arrow-down-icon flex aic jc cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setOpenMenu(!openMenu);
              }}
            >
              <ArrowDownIcon />
            </div>
          </div>
          <div
            className={`manue-profile flex flex-col ${
              openMenu ? "show" : "hide"
            }`}
          >
            <Link
              to="/update-password"
              className="tag flex aic cfff s12 b4 font"
            >
              Change Password
            </Link>
            <Link to="/accountInfo" className="tag flex aic cfff s12 b4 font">
              Account Information
            </Link>
            <div
              className="tag flex aic cfff s12 b4 font"
              onClick={(e) => userLogout()}
            >
              Logout
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
