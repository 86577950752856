import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import Datetime from "react-datetime";
import Select from "react-select";
import moment from "moment";
import {
  CloseIcon,
  SearchIcon,
  AddIcon,
  EditIcon,
  DelIcon,
} from "../../assets";
import Loader from "../Loader";
import {skipToken} from '@reduxjs/toolkit/query'
import {
  useFetchSinglePayrollProcQuery,
  useUpdatePayrollDetailByIdMutation,
} from "../../store/services/payrollService";
import { useGetComponentListQuery } from "../../store/services/componentService";
import { useGetClaimApprovedListPayrollQuery } from "../../store/services/claimsService";
import { roundTwoDecimalPoint, thousandAndDecimalFormat } from "../../utils/common";

const PayrollProcess = ({ open2, setOpen2, editData }) => {
  const { userRole, userToken, userOrgId, userId } = useSelector(
    (state) => state.authReducer
  );

  const { payrollLabel, workPeriod } = useSelector(
    (state) => state.globalReducer
  );
  const {
    data: componentList,
    isLoading: componentLoading,
    isFetching: componentFetching,
  } = useGetComponentListQuery();
  

  const { data: payrollProcessData, isFetching: payRollProcessIsFetching } =
    useFetchSinglePayrollProcQuery({
      uId: editData?.uId,
      detail_id: editData?.detail_id,
    });
  const [empleeRateDisabled, setEmpleeRateDisabled] = useState(true);
  const [empleeAmountDisabled, setEmpleeAmountDisabled] = useState(true);
  const [empleeRateCPFDisabled, setEmpleeRateCPFDisabled] = useState(true);
  const [empleeAmountCPFDisabled, setEmpleeAmountCPFDisabled] = useState(true);
  const [checkedState, setCheckedState] = useState([]);

  const [payProcessData, setPayProcessData] = useState({
    salary: 0,
    component: [{ component_id: "", amount: 0 }],
    claimReimbursment: [{ claimReimbursment: "", amount: 0, checked: false }],
    deduction: [{ typeOf: "", rate: "", amount: 0 }],
    employerContribution: [{ typeOf: "", rate: "", amount: 0 }],
    status: "",
    overtime: [{ startTime: "", endTime: "", amount: 0 }],
  });
  
  const { data: claimsApprovedList, isFetching: claimsApprovedListIsFetching } =
    useGetClaimApprovedListPayrollQuery(payProcessData?.user_id?._id ? {
      user_id: payProcessData?.user_id?._id,
    } : skipToken);

  const handleInput = (e) => {
    setPayProcessData({ ...payProcessData, [e.target.name]: e.target.value });
  };

  // Handle OverTime
  let overTimeArr = [...payProcessData?.overtime];
  const removeOverTimeRow = (index) => {
    overTimeArr = overTimeArr.filter((el, i) => i !== index);
    setPayProcessData({ ...payProcessData, overtime: overTimeArr });
  };

  const addOverTime = (e, index, property) => {
    const { name, value } = e.target;

    if (index != -1) {
      overTimeArr[index] = {
        ...overTimeArr[index],
        [property]: value,
      };
    }

    setPayProcessData({ ...payProcessData, overtime: overTimeArr });
  };
  const addOverTimeDates = (value, index, property) => {
    if (index != -1) {
      overTimeArr[index] = {
        ...overTimeArr[index],
        [property]: value,
      };
    }

    setPayProcessData({ ...payProcessData, overtime: overTimeArr });
  };

  // Handle Component
  let componentArr = [...payProcessData?.component];
  const removeComponentRow = (index) => {
    componentArr = componentArr.filter((el, i) => i !== index);
    setPayProcessData({ ...payProcessData, component: componentArr });
  };
  // Handle Name in Component table
  const updateComponentValue = (e, index, property) => {
    const { name, value, type } = e.target;
    if (index != -1) {
      componentArr[index] = {
        ...componentArr[index],
        amount: value,
      };
    }
    setPayProcessData({ ...payProcessData, component: componentArr });
  };

  const updateComponentName = (e, index, property) => {
    const salaryIs = payProcessData?.salary ? payProcessData.salary : 0;
    const tobeCC = (e?.amount ? e.amount : 0);
    const calculated = e.type === "fixed" ? tobeCC : parseInt(tobeCC) * parseInt(salaryIs) / 100
    if (index != -1) {
      componentArr[index] = {
        ...componentArr[index],
        [`${property}`]: e,
        amount: e.ccType === "deduction" ? -1 * calculated : calculated,
      };
    }
    setPayProcessData({ ...payProcessData, component: componentArr });
  };

  // Handle Deduction
  let deductionArray = [...payProcessData?.deduction];
  const updateDeductionData = (e, index, property) => {
    const { name, value } = e.target;
    if (index != -1) {
      deductionArray[index] = {
        ...deductionArray[index],
        [property]: value,
      };
    }
    setPayProcessData({ ...payProcessData, deduction: deductionArray });
  };

  // Handle Employer Contribution
  let employerContributionArray = [...payProcessData?.employerContribution];
  const updateEmployerContribution = (e, index, property) => {
    const { name, value } = e.target;

    if (index != -1) {
      employerContributionArray[index] = {
        ...employerContributionArray[index],
        [name]: value,
      };
    }
    setPayProcessData({
      ...payProcessData,
      employerContribution: employerContributionArray,
    });

  };

  useEffect(() => {
    if (!payRollProcessIsFetching && !claimsApprovedListIsFetching) {
      setPayProcessData(payrollProcessData?.data);
      let updateStateCheck = new Array(claimsApprovedList?.data?.length).fill(false);
      claimsApprovedList?.data.map((item, index) => {
        payrollProcessData?.data?.claimReimbursment.map((element) => {
          if (element === item._id) {
            updateStateCheck[index] = true;
          }
        })
      })
      setCheckedState(updateStateCheck)
    }
  }, [payrollProcessData?.data, claimsApprovedList?.data]);

  let sumOvertimes = 0;
  let sumComponents = 0;
  let sumDeductions = 0;
  let sumEmpContribution = 0;

  // const sumOvertimes = payProcessData?.overtime
  //   ?.map((item) => item.amount)
  //   .reduce((prev, curr) => prev + curr, 0);

  // const sumOvertimes = payProcessData?.overtime.reduce(
  //   (accumulator, object) => {
  //     return accumulator + object.amount;
  //   },
  //   0
  // );

  payProcessData?.overtime.forEach((element) => {
    sumOvertimes += (isNaN(element.amount)) ? 0 : Number(element.amount);
  });

  componentArr.forEach((element) => {
    sumComponents += (isNaN(element.amount)) ? 0 : Number(element.amount);
  });

  payProcessData?.deduction.forEach((element) => {
    sumDeductions += (isNaN(element.amount)) ? 0 : Number(element.amount);
  });
  payProcessData?.employerContribution.forEach((element) => {
    sumEmpContribution += (isNaN(element.amount)) ? 0 : Number(element.amount);
  });

  const calculateClaim = () => {
    let result = 0;

    checkedState.map((item, index) => {
      if (item === true) {
        claimsApprovedList?.data[index].details.map((dataSet) => {
          result += dataSet.amount ? dataSet.amount : 0;
        })
      }
    });
    return result
  }
  const calculateGross = () => {
    const result = (isNaN(payProcessData?.salary) ? 0 : Number(payProcessData?.salary)) +
    (isNaN(sumOvertimes) ? 0 : Number(sumOvertimes)) +
    (isNaN(sumComponents) ? 0 : Number(sumComponents)) +
    + Number(calculateClaim())
    return result
  }
  const calculateNet = () => {
    const result = (isNaN(payProcessData?.salary) ? 0 : Number(payProcessData?.salary)) + 
    (isNaN(sumOvertimes) ? 0 : Number(sumOvertimes)) + 
    (isNaN(sumComponents) ? 0 : Number(sumComponents)) + Number(calculateClaim()) -
    (isNaN(sumDeductions) ? 0 : Number(sumDeductions))
    return result
  }
  const handleCheckBox = (dataSet, targetIndex) => {
    let toPush = [];
    let updatedCheckedState = checkedState.map((item, index) =>
      index === targetIndex ? !item : item
    );
    updatedCheckedState.map((item, index) => {
      if (item === true) {
        toPush.push(claimsApprovedList?.data[index]._id);
      }
    });
    setPayProcessData({...payProcessData, claimReimbursment: toPush});
    setCheckedState(updatedCheckedState);
  }
  // sumGrossSalary = (isNaN(payProcessData?.salary)) ? 0 : Number(payProcessData?.salary) +
  // (isNaN(sumOvertimes)) ? 0 : Number(sumOvertimes) +
  // (isNaN(sumComponents)) ? 0 : Number(sumComponents)

  // const [calDeduction, data] = useCalPdMutation();


  // const errors = data?.error?.data ? data?.error?.data.message : null;

  // const CalculateDeduction = (e) => {
  //    e.preventDefault();
  //   calDeduction({
  //     user_id: payProcessData?.user_id?._id,
  //     calculationData: {
  //       salary: 5000,
  //       component: payProcessData?.component,
  //       overtime: payProcessData?.overtime,
  //     },
  //   });
  // };

  // API for Calculate Deduction
  const CalculateDeduction = async () => {
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/payroll/calculate/deduction/${payProcessData?.user_id?._id}`,
        {
          salary: payProcessData?.salary,
          component: payProcessData?.component,
          overtime: payProcessData?.overtime,
        },
        {
          headers: {
            authorization: userToken ? userToken : "",
            orgid: userOrgId ? userOrgId : "",
          },
        }
      );
      if (resp?.data?.data) {
        setPayProcessData({ ...payProcessData, deduction: resp?.data?.data });
      }
    } catch (err) {
      // Handle Error Here
      console.error("Deduction calculate have error!", err);
    }
  };

  // API for Calculate Employer Contribution
  const CalculateEmployerContribution = async () => {
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/payroll/calculate/contribution/${payProcessData?.user_id?._id}`,
        {
          salary: payProcessData?.salary,
          component: payProcessData?.component,
          overtime: payProcessData?.overtime,
          employerContribution: payProcessData?.employerContribution,
        },
        {
          headers: {
            authorization: userToken ? userToken : "",
            orgid: userOrgId ? userOrgId : "",
          },
        }
      );
      if (resp?.data?.data) {
        setPayProcessData({
          ...payProcessData,
          employerContribution: resp?.data?.data,
        });
      }
    } catch (err) {
      // Handle Error Here
      console.error("Deduction calculate have error!", err);
    }
  };

  const [updatePayrollDetails, response] = useUpdatePayrollDetailByIdMutation();
  const errors = response?.error?.data ? response?.error?.data.message : null;

  const updatePayrollDetailById = (e) => {
    e.preventDefault();
    updatePayrollDetails({
      uId: editData?.uId,
      detail_id: editData?.detail_id,
      payProcessData,
    });
  };


  return (
    <div className="payroll-process flex flex-col">
      {(!payRollProcessIsFetching && !componentFetching) ? (
        <div className="wrap flex flex-col w-full">
          <div className="page-hdr flex items-center">
            <div className="heading flex flex-col items-center flex-1">
              <div className="tag">
                Payroll process for
                <span className="ml-2">
                  {/* {payrollLabel?.year}_{payrollLabel?.month}_
                  {payrollLabel?.name} */}
                  {payrollProcessData?.label?.year}_{payrollProcessData?.label?.month}_
                  {payrollProcessData?.label?.name}
                </span>
              </div>
              <div className="date">
                {`${moment(workPeriod?.from).format("D")}-${moment(
                  workPeriod?.from
                ).format("MM")}-${moment(workPeriod?.from).format("YYYY")}`}
                <span className="mx-2">/</span>
                {`${moment(workPeriod?.to).format("D")}-${moment(
                  workPeriod?.to
                ).format("MM")}-${moment(workPeriod?.to).format("YYYY")}`}
              </div>
            </div>
            <div
              className="cross-icon flex aic jc cursor-pointer"
              onClick={(e) => setOpen2(false)}
            >
              <CloseIcon />
            </div>
          </div>
          <div className="meta flex flex-col w-full">
            <div className="grid-row">
              <div className="field flex flex-col">
                <div className="lbl">First name</div>
                <input
                  type="text"
                  value={payProcessData?.user_id?.firstName}
                  disabled={true}
                  className="txt cleanbtn"
                  placeholder="First name"
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Salary</div>
                <input
                  type="number"
                  name="salary"
                  value={payProcessData?.salary}
                  onChange={handleInput}
                  className="txt cleanbtn"
                  placeholder="Salary"
                />
              </div>
            </div>
            <div className="over-time-sec flex flex-col w-full">
              <div className="tag py-3">Overtimes</div>
              <div className="btn-add flex aic">
                <div className="lbl s14 mr-4">Add Overtime</div>
                <div
                  className="add-icon cursor-pointer"
                  onClick={(e) => {
                    setPayProcessData({
                      ...payProcessData,
                      overtime: [
                        ...payProcessData.overtime,
                        {
                          startTime: new Date(),
                          endTime: new Date(),
                          amount: 0,
                        },
                      ],
                    });
                  }}
                >
                  <AddIcon />
                </div>
              </div>
              <div className="table-blk flex w-full">
                <div className="tbl flex flex-col">
                  <div className="row flex aic">
                    <div className="col flex">
                      <div className="col-item">Start Time</div>
                    </div>
                    <div className="col flex">
                      <div className="col-item">End Time</div>
                    </div>
                    <div className="col flex">
                      <div className="col-item">Overtime Amount</div>
                    </div>
                    <div className="col flex">
                      <div className="col-item"></div>
                    </div>
                  </div>
                  {payProcessData.overtime?.length > 0 &&
                    payProcessData.overtime?.map((item, index) => (
                      <div key={index} className="row flex aic">
                        <div className="col flex">
                          <div className="date-picker flex aic jc">
                            <Datetime
                              name="startTime"
                              closeOnSelect={true}
                              value={
                                item.startTime
                                  ? new Date(item.startTime)
                                  : new Date()
                              }
                              onChange={(value) => {
                                addOverTimeDates(
                                  new Date(value),
                                  index,
                                  "startTime"
                                );
                              }}
                              timeFormat={true}
                              dateFormat="MMMM Do, YYYY"
                              className="start-date cleanbtn"
                            />
                          </div>
                        </div>
                        <div className="col flex">
                          <div className="date-picker flex aic jc">
                            <Datetime
                              name="endTime"
                              closeOnSelect={true}
                              value={
                                item.endTime
                                  ? new Date(item.endTime)
                                  : new Date()
                              }
                              onChange={(value) => {
                                addOverTimeDates(
                                  new Date(value),
                                  index,
                                  "endTime"
                                );
                              }}
                              timeFormat={true}
                              dateFormat="MMMM Do, YYYY"
                              className="start-date cleanbtn"
                            />
                          </div>
                        </div>
                        <div className="col flex">
                          <input
                            type="text"
                            name="amount"
                            value={item.amount}
                            onChange={(e) => addOverTime(e, index, "amount")}
                            className="txt cleanbtn"
                            placeholder="Amount"
                          />
                        </div>
                        <div className="col flex">
                          <div className="col-item flex items-center justify-end">
                            {/* <div className="icon cursor-pointer">
                              <EditIcon />
                            </div> */}

                            <div
                              className="icon ml-4 cursor-pointer"
                              onClick={(e) => removeOverTimeRow(index)}
                            >
                              <DelIcon />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="total-amount flex items-center justify-end">
                <div className="field flex flex-col">
                  <div className="lbl">Total Overtime</div>
                  <div
                    type="text"
                    className="txt cleanbtn disabled-input"
                    placeholder="First name"
                  >
                    {sumOvertimes ? sumOvertimes : 0}
                  </div>
                </div>
              </div>
            </div>
            <div className="over-time-sec flex flex-col w-full">
              <div className="tag py-3">Components</div>
              <div className="btn-add flex aic">
                <div className="lbl s14 mr-4">Add Component</div>
                <div
                  className="add-icon cursor-pointer"
                  onClick={(e) => {
                    setPayProcessData({
                      ...payProcessData,
                      component: [
                        ...payProcessData.component,
                        {
                          amount: 0,
                          component_id: "",
                        },
                      ],
                    });
                  }}
                >
                  <AddIcon />
                </div>
              </div>
              <div className="table-blk flex w-full">
                <div className="tbl flex flex-col">
                  <div className="row flex aic">
                    <div className="col flex">
                      <div className="col-item">Name</div>
                    </div>
                    <div className="col flex">
                      <div className="col-item">Value</div>
                    </div>
                    <div className="col flex">
                      <div className="col-item">Type</div>
                    </div>
                    <div className="col flex">
                      <div className="col-item"></div>
                    </div>
                  </div>
                  {componentArr?.length > 0 &&
                    componentArr?.map((item, index) => (
                      <div key={index} className="row flex aic">
                        <div className="col flex">
                          <div className="col-item">
                            <Select
                              // defaultValue={item.name}
                              value={componentList?.data.filter(function(option) {
                                return option._id === item.component_id._id;
                              })}
                              onChange={(e) => {
                                updateComponentName(e, index, "component_id");
                              }}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option._id}
                              options={componentList?.data}
                              name="name"
                              className="select w-4/5"
                              placeholder="Name"
                            />
                          </div>
                        </div>
                        <div className="col flex">
                          <input
                            type="number"
                            name="amount"
                            className="txt cleanbtn"
                            placeholder="Amount"
                            value={item.amount}
                            onChange={(e) => {
                              updateComponentValue(e, index, "amount");
                            }}
                            style={{marginRight : "20px"}}
                          />
                        </div>
                        <div className="col flex">
                          
                        <input
                            type="text"
                            name="componenttype"
                            className="txt cleanbtn"
                            placeholder="Type"
                            value={item.component_id.type}
                            disabled
                          />
                        </div>

                        <div className="col flex">
                          <div className="col-item flex items-center justify-end">
                            {/* <div className="icon cursor-pointer">
                              <EditIcon />
                            </div> */}

                            <div
                              className="icon ml-4 cursor-pointer"
                              onClick={(e) => removeComponentRow(index)}
                            >
                              <DelIcon />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="total-amount flex items-center justify-end">
                <div className="field flex flex-col">
                  <div className="lbl">Total Components</div>
                  <div
                    type="text"
                    className="txt cleanbtn disabled-input"
                    placeholder="First name"
                  >
                    {sumComponents ? sumComponents : 0}
                  </div>
                </div>
              </div>
            </div>
            <div className="over-time-sec flex flex-col w-full">
              <div className="tag py-3">Claim Reimbursment</div>

              {claimsApprovedList?.data?.length > 0 && 
                  claimsApprovedList?.data?.map((item, index) => (
                    <div className="grid-row">
                        <div key={index} className="field flex flex-col">
                          <div className="request-date flex items-center">
                            <input type="checkbox" 
                              className="check-box w-4 h-4" 
                              checked={checkedState[index]} 
                              onChange={(e) => handleCheckBox(item, index)}

                              />
                            <div className="checkbox-tag s14 ml-4 mr-12">
                              Request Date
                            </div>
                            <div className="checkbox-val s14 ml-4 mr-8">
                              {`${moment(item?.requestDate).format("D")}-${moment(
                                item?.requestDate
                              ).format("MM")}-${moment(item?.requestDate).format(
                                "YYYY"
                              )}`}{" "}
                              ($ {thousandAndDecimalFormat(item?.details.reduce((a,v) =>  a = a + v.amount , 0 ), true, 2)} )
                            </div>
                          </div>
                          {item?.details.map((val, idx) => (
                            <div className="claims-list flex flex-col">
                              <div className="claim-item flex items-center ml-8 my-3">
                                <div className="p-1 bg-Black rounded-full"></div>
                                <div className="checkbox-tag s14 ml-4 mr-12">
                                  {val.description}
                                </div>
                                <div className="checkbox-val s14 ml-4 mr-8">
                                  {`${moment(val?.receiptDate).format(
                                    "D"
                                  )}-${moment(val?.receiptDate).format(
                                    "MM"
                                  )}-${moment(val?.receiptDate).format(
                                    "YYYY"
                                  )}`}{" "}
                                  (${thousandAndDecimalFormat(val?.amount, true, 2)})
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                    </div>
                  ))
              }

              <div className="total-amount flex flex-col items-end justify-end">
                <div className="field flex flex-col mb-5">
                  <div className="lbl">Total Claims</div>
                  <div
                    type="text"
                    className="txt cleanbtn disabled-input"
                    placeholder="First name"
                  >
                    {calculateClaim()}
                  </div>
                </div>
                <div className="field flex flex-col">
                  <div className="lbl">Gross Salary</div>
                  <div
                    type="text"
                    className="txt cleanbtn disabled-input"
                    placeholder="First name"
                  >
                    {calculateGross()}
                  </div>
                </div>
              </div>
            </div>
            <div className="over-time-sec flex flex-col w-full">
              <div className="flex aic justify-between">
                <div className="tag py-3">Deductions</div>
                <div
                  className="btn button rounded-lg mt-4"
                  onClick={(e) => CalculateDeduction(e)}
                >
                  Calculate Deduction
                </div>
              </div>
              {deductionArray?.map((item, index) => (
                <div key={index} className="flex flex-col">
                  <div className="tag py-3">{item.typeOf}</div>
                  <div className="grid-row">
                    <div className="field flex flex-col">
                      <div className="lbl">Rate</div>
                      <div className={`flex aic txt ${empleeRateCPFDisabled ? "disabled-input" : ""}`}>
                        <input
                          type="text"
                          className="cleanbtn w-full"
                          placeholder="Rate"
                          name="rate"
                          value={item.rate}
                          onChange={(e) =>
                            updateDeductionData(e, index, "rate")
                          }
                          disabled={empleeRateCPFDisabled}
                        />
                        <div
                          className="icon flex aic jc cursor-pointer"
                          onClick={(e) => {
                            setEmpleeRateCPFDisabled(!empleeRateCPFDisabled);
                          }}
                        >
                          <EditIcon />
                        </div>
                      </div>
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl">Amount</div>
                      <div className={`flex aic txt ${empleeAmountCPFDisabled ? "disabled-input" : ""}`}>
                        <input
                          type="number"
                          className="cleanbtn w-full"
                          placeholder="Amount"
                          name="amount"
                          value={item.amount}
                          onChange={(e) =>
                            updateDeductionData(e, index, "amount")
                          }
                          disabled={empleeAmountCPFDisabled}
                        />
                        <div
                          className="icon flex aic jc cursor-pointer"
                          onClick={(e) =>
                            setEmpleeAmountCPFDisabled(!empleeAmountCPFDisabled)
                          }
                        >
                          <EditIcon />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {/* <div className="tag py-6">SHGs</div>
              <div className="grid-row">
                <div className="field flex flex-col">
                  <div className="lbl">Type</div>
                  <input
                    type="text"
                    className="txt cleanbtn"
                    placeholder="Type"
                  />
                </div>
                <div className="field flex flex-col">
                  <div className="lbl">Rate</div>
                  <div className="flex aic txt">
                    <input
                      type="text"
                      className="cleanbtn w-full"
                      placeholder="Rate"
                      disabled={empleeAmountDisabled}
                    />
                    <div
                      className="icon flex aic jc cursor-pointer"
                      onClick={(e) =>
                        setEmpleeAmountDisabled(!empleeAmountDisabled)
                      }
                    >
                      <EditIcon />
                    </div>
                  </div>
                </div>
                <div className="field flex flex-col">
                  <div className="lbl">Amount</div>
                  <div className="flex aic txt">
                    <input
                      type="text"
                      className="cleanbtn w-full"
                      placeholder="Amount"
                      disabled={empleeAmountDisabled}
                    />
                    <div
                      className="icon flex aic jc cursor-pointer"
                      onClick={(e) =>
                        setEmpleeAmountDisabled(!empleeAmountDisabled)
                      }
                    >
                      <EditIcon />
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="total-amount flex items-center justify-end">
                <div className="field flex flex-col">
                  <div className="lbl">Total Deductions</div>
                  <div
                    type="text"
                    className="txt cleanbtn disabled-input"
                    placeholder="First name"
                  >
                    {sumDeductions ? sumDeductions : 0}
                  </div>
                </div>
              </div>
            </div>
            <div className="over-time-sec flex flex-col w-full">
              <div className="flex aic justify-between">
                <div className="tag py-3">Employer Contribution</div>
                <div
                  className="btn button rounded-lg mt-4"
                  onClick={(e) => CalculateEmployerContribution(e)}
                >
                  Calculate Contribution
                </div>
              </div>
              {employerContributionArray?.map((item, index) => (
                <div key={index} className="flex flex-col">
                  <div className="tag py-3">{item.typeOf}</div>
                  <div className="grid-row">
                    <div className="field flex flex-col">
                      <div className="lbl">Rate</div>
                      <div className={`flex aic txt ${empleeRateDisabled ? "disabled-input" : ""}`}>
                        <input
                          type="text"
                          className="cleanbtn w-full"
                          placeholder="Rate"
                          name="rate"
                          value={item.rate}
                          onChange={(e) =>
                            updateEmployerContribution(e, index, "rate")
                          }
                          disabled={empleeRateDisabled}
                        />
                        <div
                          className="icon flex aic jc cursor-pointer"
                          onClick={(e) =>
                            setEmpleeRateDisabled(!empleeRateDisabled)
                          }
                        >
                          <EditIcon />
                        </div>
                      </div>
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl">Amount</div>
                      <div className={`flex aic txt ${empleeAmountDisabled ? "disabled-input" : ""}`}>
                        <input
                          type="text"
                          className="cleanbtn w-full"
                          placeholder="Amount"
                          name="amount"
                          value={item.amount}
                          onChange={(e) =>
                            updateEmployerContribution(e, index, "amount")
                          }
                          disabled={empleeAmountDisabled}
                        />
                        <div
                          className="icon flex aic jc cursor-pointer"
                          onClick={(e) =>
                            setEmpleeAmountDisabled(!empleeAmountDisabled)
                          }
                        >
                          <EditIcon />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {/* <div className="tag py-6">SHGs</div>
              <div className="grid-row">
                <div className="field flex flex-col">
                  <div className="lbl">Rate</div>
                  <div className="flex aic txt">
                    <input
                      type="text"
                      className="cleanbtn w-full"
                      placeholder="Rate"
                      disabled={empleeAmountDisabled}
                    />
                    <div
                      className="icon flex aic jc cursor-pointer"
                      onClick={(e) =>
                        setEmpleeAmountDisabled(!empleeAmountDisabled)
                      }
                    >
                      <EditIcon />
                    </div>
                  </div>
                </div>
                <div className="field flex flex-col">
                  <div className="lbl">Amount</div>
                  <div className="flex aic txt">
                    <input
                      type="text"
                      className="cleanbtn w-full"
                      placeholder="Amount"
                      disabled={empleeAmountDisabled}
                    />
                    <div
                      className="icon flex aic jc cursor-pointer"
                      onClick={(e) =>
                        setEmpleeAmountDisabled(!empleeAmountDisabled)
                      }
                    >
                      <EditIcon />
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="total-amount flex items-center justify-end">
                <div className="field flex flex-col">
                  <div className="lbl">Total Employer Contribution</div>
                  <div
                    type="text"
                    className="txt cleanbtn disabled-input"
                    placeholder="First name"
                  >
                    {sumEmpContribution ? sumEmpContribution : 0}
                  </div>
                </div>
              </div>
            </div>
            <div className="total-amount flex items-end flex-col justify-end">
              <div className="field flex flex-col mb-8">
                <div className="lbl s18 b6">Net Salary</div>
                <div type="text" className="txt cleanbtn disabled-input">
                  {calculateNet()}
                </div>
              </div>
              <div
                className="btn button"
                onClick={(e) => updatePayrollDetailById(e)}
              >
                Save
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default PayrollProcess;
