import React from "react";

function EditIconPy(status, typePage, statusTwo) {
  let pathText = "disabled";
  if (typePage, "payroll") {
    pathText = (`${status.status}` === 'Draft' || status?.status === undefined) ? "enabled" : "disabled"
  } else if (typePage, "claim") {
    if (statusTwo === 'pending' &&  status === 'pending') {
      pathText = "enabled"
    }
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 29 28"
    >
      <path
        className={`${pathText}`}
        d="M25.14 25.668h-21a.881.881 0 01-.874-.875c0-.478.396-.875.875-.875h21c.478 0 .875.397.875.875a.881.881 0 01-.875.875zM22.832 4.06c-2.263-2.264-4.48-2.322-6.801 0L14.619 5.47a.486.486 0 00-.117.467 9.483 9.483 0 006.452 6.451.467.467 0 00.478-.117l1.4-1.411c1.155-1.143 1.715-2.252 1.715-3.372.012-1.155-.548-2.275-1.715-3.43zM18.85 13.452a12.921 12.921 0 01-.98-.513 10.271 10.271 0 01-.746-.49 7.029 7.029 0 01-.654-.502 1.413 1.413 0 01-.198-.175 9.86 9.86 0 01-1.202-1.213c-.035-.024-.093-.105-.175-.21-.116-.14-.315-.374-.49-.642a6.396 6.396 0 01-.455-.688 15.04 15.04 0 01-.513-.957 8.946 8.946 0 01-.124-.277.516.516 0 00-.836-.175l-6.775 6.775a1.404 1.404 0 00-.327.642l-.63 4.468c-.116.794.105 1.54.595 2.042.42.408 1.004.63 1.634.63.14 0 .28-.012.42-.035l4.48-.63c.21-.035.501-.175.641-.327l6.768-6.768a.523.523 0 00-.177-.846 22.193 22.193 0 01-.256-.11z"
      ></path>
    </svg>
  );
}

export default EditIconPy;
