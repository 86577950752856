import React from "react";

function ActiveUserIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      fill="#4e7a7d"
      stroke="#4e7a7d"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
      viewBox="0 0 24 24"
    >
      <path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2M8.5 3a4 4 0 100 8 4 4 0 100-8zM17 11l2 2 4-4"></path>
    </svg>
  );
}

export default ActiveUserIcon;
