import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const usersService = createApi({
  reducerPath: "users",
  tagTypes: "usersData",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,

    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const authorization = reducers.authReducer?.userToken;
      const orgid = reducers.authReducer?.userOrgId;
      headers.set("authorization", authorization ? authorization : "");
      headers.set("orgid", orgid ? orgid : "");
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => {
    return {
      //   Create New User
      createUser: builder.mutation({
        query: (saveUserData) => {
          return { url: "/api/user", method: "POST", body: saveUserData };
        },
        invalidatesTags: ["usersData"],
      }),

      //   Revoke the User access
      revokeUser: builder.mutation({
        query: (uId) => {
          return { url: `/api/user/revoke/${uId}`, method: "PUT" };
        },
        invalidatesTags: ["usersData"],
      }),

      //   Re-revoke the User access
      reRevokeUser: builder.mutation({
        query: (uId) => {
          return { url: `/api/user/reRevoke/${uId}`, method: "PUT" };
        },
        invalidatesTags: ["usersData"],
      }),

      //   Update the User
      updateUser: builder.mutation({
        query: (data) => {
          return {
            url: `/api/user/${data.uId}`,
            method: "PUT",
            body: data,
          };
        },
        invalidatesTags: ["usersData"],
      }),

      //   Update the User Password
      updateUserPassword: builder.mutation({
        query: (passwordData) => {
          return {
            url: `/api/auth/change/password`,
            method: "PUT",
            body: passwordData,
          };
        },
        invalidatesTags: ["usersData"],
      }),

      //   Get the List of Users
      getUsersList: builder.query({
        query: () => {
          return {
            url: "/api/user",
            method: "GET",
          };
        },
        providesTags: ["usersData"],
      }),

      // Get the General Company information
      getGeneralInfo: builder.query({
        query: () => {
          return {
            url: "/api/corporate",
            method: "GET",
          };
        },
        providesTags: ["usersData"],
      }),

      //   Get the Single User
      fetchSingleUser: builder.query({
        query: (uId) => {
          return {
            url: `/api/user/${uId}`,
            method: "GET",
          };
        },
        providesTags: ["usersData"],
      }),

      getAllApps: builder.query({
        query: () => {
          return {
            url: `/api/app`,
            method: "GET",
          };
        },
        providesTags: ["usersData"],
      }),
    };
  },
});

export const {
  useCreateUserMutation,
  useGetUsersListQuery,
  useGetGeneralInfoQuery,
  useFetchSingleUserQuery,
  useGetAllAppsQuery,
  useRevokeUserMutation,
  useReRevokeUserMutation,
  useUpdateUserMutation,
  useUpdateUserPasswordMutation,
} = usersService;

export default usersService;
