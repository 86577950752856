import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Datetime from "react-datetime";
import moment from "moment";
import StatutoryFundsDetail from "./StatutoryFundsDetail";
import NetPayDetail from "./NetPayDetail";
import PaymentSummary from "./PaymentSummary";
import SalarySummary from "./SalarySummary";
import CPFSummary from "./CPFSummary";
import AddPayrollUsers from "./AddPayrollUsers";

import Loader from "../Loader";
import Spinner from "../Spinner";
import Modal from "../Modal";

import {
  SearchIcon,
  EditIcon,
  DelIcon,
  UserAddIcon,
  DownloadIcon,
  CalendarIcon,
  AddIcon,
  DropDownIcon,
  ExportIcon,
} from "../../assets";

import { useGetOptionsQuery } from "../../store/services/generalService";
import { useFetchSinglePayrollDetailQuery } from "../../store/services/payrollService";
import { thousandAndDecimalFormat, downloadFile } from "../../utils/common";
import { exportBulk } from "../../store/services/export.service";

const PayrollDetail = ({ data }) => {
  localStorage.setItem("payroll_uId", data?.uId);
  const [allData, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const {
    data: payrollDetailData,
    isFetching,
    isLoading,
  } = useFetchSinglePayrollDetailQuery(data?.uId);

  const [statutoryFund, setStatutoryFund] = useState();
  const [netPayDetail, setNetPayDetail] = useState();
  const [userId, setUserId] = useState();
  const [pyDetails, setPyDetails] = useState();
  const [paymentSummaryData, setPaymentSummaryData] = useState();
  const [salarySummaryData, setSalarySummaryData] = useState();
  const [cpfSummaryData, setCpfSummaryData] = useState();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);

  const [selectedTab, setSelectedTab] = useState("payment");
  const [payDate, setPayDate] = useState(new Date().getTime());
  const [fromDate, setFromDate] = useState(new Date().getTime());
  const [toDate, setToDate] = useState(new Date().getTime());

  useEffect(() => {
    if (!isFetching && !isLoading) {
      setUserId(payrollDetailData?.data?.uId);
      setData(payrollDetailData?.data);
      setFilteredData(payrollDetailData?.data);
      setPyDetails(filteredData?.payrollDetails);
      setPaymentSummaryData(filteredData?.paymentSummary);
      setSalarySummaryData(filteredData?.salarySummary);
      setCpfSummaryData(filteredData?.cpfSummary);
    }
  }, [payrollDetailData?.data]);

  useEffect(() => {
    // if (!isFetching && !isLoading) {
    setPyDetails(filteredData?.payrollDetails);
    setPaymentSummaryData(filteredData?.paymentSummary);
    setSalarySummaryData(filteredData?.salarySummary);
    setCpfSummaryData(filteredData?.cpfSummary);

    // }
  }, [filteredData]);
  // response is stream
  // const url = window.URL.createObjectURL(new Blob([zipFileData.error.data], { type: "application/zip" }));
  // const link = document.createElement("a");
  // link.target = "_blank";
  // link.download = "bulk_payslip";
  // link.href = url
  // link.click();

  const handleExportBulkPayslip = () => {
    exportBulk({ uId: data?.uId }).then((res) => {
      if (res) {
        const title = `${payrollDetailData?.data?.label?.year}_${payrollDetailData?.data?.label?.month}_${payrollDetailData?.data?.label?.name}`;
        downloadFile(title, "zip", res);
      }
    });
  };

  const getSelectedTabData = () => {
    if (selectedTab === "payment") {
      return paymentSummaryData;
    } else if (selectedTab === "salary") {
      return salarySummaryData;
    } else {
      return cpfSummaryData;
    }
  };

  const { data: options, isLoading: optionsIsLoading } = useGetOptionsQuery();
  const BankName = (bankValue) => {
    var newArray = options?.data?.bankList?.filter(function (el) {
      return el?.value == bankValue;
    });

    return newArray?.length ? newArray[0]?.display : "";
  };
  const searchPaymentFieldIs = [
    "firstName",
    "netSalary",
    "bankName",
    "bankAccount",
    "totalCPFAmount",
    "CPFAccount",
    "status",
  ];
  const searchSalaryFieldIs = [
    "firstName",
    "salary",
    "overtime",
    "component",
    "claim",
    "deduction",
    "employerContribution",
    "netSalary",
    "status",
  ];
  const searchCpfFieldIs = [
    "cpfEmployeeAmount",
    "cpfEmployeeRate",
    "cpfEmployerAmount",
    "cpfEmployerRate",
    "firstName",
    "sdl",
    "shgsAmount",
    "shgsType",
    "status",
    "totalAmount",
  ];
  const searchText = (target, value) => {
    return String(target).toLowerCase().indexOf(value) > -1 ? true : false;
  };
  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase();
    if (event.target.value !== "") {
      let allowedIndex = [];
      let result = [];
      if (selectedTab === "payment") {
        result = allData.paymentSummary.filter((data, index) => {
          let flag = false;
          for (let i = 0; i < searchPaymentFieldIs.length; i++) {
            const targetField = searchPaymentFieldIs[i];
            if (data[`${targetField}`]) {
              if (targetField === "bankName") {
                const targetDataValue = String(
                  BankName(data[`${targetField}`])
                );
                flag = searchText(targetDataValue, value);
                if (flag) break;
              } else {
                flag = searchText(data[`${targetField}`], value);
                if (flag) break;
              }
            }
          }

          if (flag) {
            allowedIndex.push(index);
            return data;
          }
        });
      } else if (selectedTab === "salary") {
        result = allData.salarySummary.filter((data, index) => {
          let flag = false;
          for (let i = 0; i < searchSalaryFieldIs.length; i++) {
            const targetField = searchSalaryFieldIs[i];
            if (data[`${targetField}`]) {
              if (targetField === "component") {
                for (let j = 0; j < data[`${targetField}`].length; j++) {
                  const element = data[`${targetField}`][j];
                  if (
                    searchText(element.name, value) ||
                    searchText(element.value, value)
                  ) {
                    flag = true;
                  }
                }
                if (flag) break;
              } else if (targetField === "employerContribution") {
                for (let j = 0; j < data[`${targetField}`].length; j++) {
                  const element = data[`${targetField}`][j];
                  if (
                    searchText(element.name, value) ||
                    searchText(element.value, value)
                  ) {
                    flag = true;
                  }
                }
                if (flag) break;
              } else if (targetField === "deduction") {
                for (let j = 0; j < data[`${targetField}`].length; j++) {
                  const element = data[`${targetField}`][j];
                  if (
                    searchText(element.name, value) ||
                    searchText(element.value, value)
                  ) {
                    flag = true;
                  }
                }
                if (flag) break;
              } else {
                flag = searchText(data[`${targetField}`], value);
                if (flag) break;
              }
            }
          }

          if (flag) {
            allowedIndex.push(index);
            return data;
          }
        });
      } else if (selectedTab === "cpf") {
        result = allData.cpfSummary.filter((data, index) => {
          let flag = false;
          for (let i = 0; i < searchCpfFieldIs.length; i++) {
            const targetField = searchCpfFieldIs[i];
            if (data[`${targetField}`]) {
              flag = searchText(data[`${targetField}`], value);
              if (flag) break;
            }
          }

          if (flag) {
            allowedIndex.push(index);
            return data;
          }
        });
      }

      let resultDetailsSum = [];
      let resultPaymentSum = [];
      let resultSalarySum = [];
      let resultCpfSum = [];

      resultDetailsSum = allData.payrollDetails.filter((data, index) => {
        const found = allowedIndex.includes(index);
        if (found) return data;
      });
      resultPaymentSum = allData.paymentSummary.filter((data, index) => {
        const found = allowedIndex.includes(index);
        if (found) return data;
      });
      resultSalarySum = allData.salarySummary.filter((data, index) => {
        const found = allowedIndex.includes(index);
        if (found) return data;
      });
      resultCpfSum = allData.cpfSummary.filter((data, index) => {
        const found = allowedIndex.includes(index);
        if (found) return data;
      });

      setFilteredData((prevState) => ({
        ...prevState,
        payrollDetails: resultDetailsSum,
        paymentSummary: resultPaymentSum,
        salarySummary: resultSalarySum,
        cpfSummary: resultCpfSum,
      }));
    } else {
      setFilteredData(allData);
    }
  };
  return (
    <div className="payroll-detail flex flex-col">
      {!isFetching && !isLoading ? (
        <>
          <div className="detail-hdr flex aic">
            <div className="hdr-left flex flex-1 flex-col">
              <div className="page-tag mr-6">Work Period</div>
              <div className="selct-date flex aic">
                <div className="field flex aic mr-3">
                  <div className="lbl">From</div>
                  <div className="date-picker flex aic justify-between ">
                    <>
                      {`${moment(filteredData?.workPeriod?.from).format(
                        "D"
                      )}-${moment(filteredData?.workPeriod?.from).format(
                        "MM"
                      )}-${moment(filteredData?.workPeriod?.from).format(
                        "YYYY"
                      )}`}
                    </>
                    {/* <Datetime
                  closeOnSelect={true}
                  value={payDate ? payDate : new Date().getTime()}
                  onChange={(value) => {
                    setPayDate(new Date(value).getTime());
                  }}
                  timeFormat={false}
                  dateFormat="DD-MM-YYYY"
                  className="start-date cleanbtn"
                /> */}
                    <div className="ico">
                      <CalendarIcon />
                    </div>
                  </div>
                </div>
                <div className="field flex aic">
                  <div className="lbl">To</div>
                  <div className="date-picker flex aic justify-between ">
                    {/* <Datetime
                  closeOnSelect={true}
                  value={fromDate ? fromDate : new Date().getTime()}
                  onChange={(value) => {
                    setFromDate(new Date(value).getTime());
                  }}
                  timeFormat={false}
                  dateFormat="DD-MM-YYYY"
                  className="start-date cleanbtn"
                /> */}
                    <>
                      {`${moment(filteredData?.workPeriod?.to).format(
                        "D"
                      )}-${moment(filteredData?.workPeriod?.to).format(
                        "MM"
                      )}-${moment(filteredData?.workPeriod?.to).format(
                        "YYYY"
                      )}`}
                    </>
                    <div className="ico">
                      <CalendarIcon />
                    </div>
                  </div>
                </div>
              </div>
              <div className="page-tag mr-6 pb-3">Pay Date</div>
              <div className="date-picker flex aic justify-between ">
                {/* <Datetime
              closeOnSelect={true}
              value={toDate ? toDate : new Date().getTime()}
              onChange={(value) => {
                setToDate(new Date(value).getTime());
              }}
              timeFormat={false}
              dateFormat="DD-MM-YYYY"
              className="start-date cleanbtn"
            /> */}
                <>
                  {`${moment(filteredData?.payDate).format("D")}-${moment(
                    filteredData?.payDate
                  ).format("MM")}-${moment(filteredData?.payDate).format(
                    "YYYY"
                  )}`}
                </>
                <div className="ico">
                  <CalendarIcon />
                </div>
              </div>
            </div>
            <div className="hdr-right flex aic flex-1">
              <div className="meta">
                <div className="meta-item flex flex-col">
                  <div className="page-tag">Total Statutory Fund</div>
                  <div className="price">
                    {thousandAndDecimalFormat(
                      payrollDetailData?.data?.totalStatutoryFunds?.totalFunds,
                      true,
                      2
                    )}
                    SGD
                  </div>
                  <div
                    className="lbl-detail"
                    onClick={(e) => {
                      setOpen(true);
                      setStatutoryFund(
                        payrollDetailData?.data?.totalStatutoryFunds
                      );
                    }}
                  >
                    View Details
                  </div>
                </div>
                <div className="meta-item flex flex-col">
                  <div className="page-tag">Total Net Pay</div>
                  <div className="price">
                    {thousandAndDecimalFormat(
                      payrollDetailData?.data?.totalNetPay?.totalNetPay,
                      true,
                      2
                    )}{" "}
                    SGD
                  </div>
                  <div
                    className="lbl-detail"
                    onClick={(e) => {
                      setOpen2(true);
                      setNetPayDetail(payrollDetailData?.data?.totalNetPay);
                    }}
                  >
                    View Details
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main-block flex flex-col">
            <div className="tabs flex aic">
              <div
                className={`tab-box flex aic jc ${
                  selectedTab === "payment" ? "active" : ""
                }`}
                onClick={(e) => setSelectedTab("payment")}
              >
                Payment Summary
              </div>
              <div
                className={`tab-box flex aic jc ${
                  selectedTab === "salary" ? "active" : ""
                }`}
                onClick={(e) => setSelectedTab("salary")}
              >
                Salary Summary
              </div>
              <div
                className={`tab-box flex aic jc ${
                  selectedTab === "cpf" ? "active" : ""
                }`}
                onClick={(e) => setSelectedTab("cpf")}
              >
                CPF Summary
              </div>
            </div>
            <div className="blk-actions flex aic">
              <div className="action-left flex">
                <div
                  className="btn button aic justify-around"
                  onClick={(e) => setOpen3(true)}
                >
                  Add user
                  <div className="icon flex aic jc">
                    <AddIcon />
                  </div>
                </div>
              </div>
              <div className="action-center flex aic jc">
                <div className="search-box flex aic">
                  <input
                    type="text"
                    className="txt cleanbtn cursor-pointer"
                    placeholder="Search here"
                    onChange={(event) => handleSearch(event)}
                  />
                  <div className="icon flex aic jc">
                    <SearchIcon />
                  </div>
                </div>
              </div>
              <div className="action-right flex flex-col">
                <div
                  className="btn button aic justify-around"
                  onClick={() => handleExportBulkPayslip()}
                >
                  Generate Payment Summary
                  <div className="icon flex aic jc">
                    <DownloadIcon />
                  </div>
                </div>
              </div>
            </div>
            {selectedTab === "payment" ? (
              <PaymentSummary
                data={paymentSummaryData}
                uId={userId}
                periodStart={`${moment(
                  payrollDetailData?.data?.workPeriod?.from
                ).format("D")}-${moment(
                  payrollDetailData?.data?.workPeriod?.from
                ).format("MM")}-${moment(
                  payrollDetailData?.data?.workPeriod?.from
                ).format("YYYY")}`}
                periodEnd={`${moment(
                  payrollDetailData?.data?.workPeriod?.to
                ).format("D")}-${moment(
                  payrollDetailData?.data?.workPeriod?.to
                ).format("MM")}-${moment(
                  payrollDetailData?.data?.workPeriod?.to
                ).format("YYYY")}`}
                pyDetails={pyDetails}
                payDate={moment(payrollDetailData?.data?.payDate).format(
                  "DD-MM-YYYY"
                )}
              />
            ) : selectedTab === "salary" ? (
              <SalarySummary
                data={salarySummaryData}
                uId={userId}
                periodStart={`${moment(
                  payrollDetailData?.data?.workPeriod?.from
                ).format("D")}-${moment(
                  payrollDetailData?.data?.workPeriod?.from
                ).format("MM")}-${moment(
                  payrollDetailData?.data?.workPeriod?.from
                ).format("YYYY")}`}
                periodEnd={`${moment(
                  payrollDetailData?.data?.workPeriod?.to
                ).format("D")}-${moment(
                  payrollDetailData?.data?.workPeriod?.to
                ).format("MM")}-${moment(
                  payrollDetailData?.data?.workPeriod?.to
                ).format("YYYY")}`}
                pyDetails={pyDetails}
                payDate={moment(payrollDetailData?.data?.payDate).format(
                  "DD-MM-YYYY"
                )}
              />
            ) : selectedTab === "cpf" ? (
              <CPFSummary
                data={cpfSummaryData}
                uId={userId}
                periodStart={`${moment(
                  payrollDetailData?.data?.workPeriod?.from
                ).format("D")}-${moment(
                  payrollDetailData?.data?.workPeriod?.from
                ).format("MM")}-${moment(
                  payrollDetailData?.data?.workPeriod?.from
                ).format("YYYY")}`}
                periodEnd={`${moment(
                  payrollDetailData?.data?.workPeriod?.to
                ).format("D")}-${moment(
                  payrollDetailData?.data?.workPeriod?.to
                ).format("MM")}-${moment(
                  payrollDetailData?.data?.workPeriod?.to
                ).format("YYYY")}`}
                pyDetails={pyDetails}
                payDate={moment(payrollDetailData?.data?.payDate).format(
                  "DD-MM-YYYY"
                )}
              />
            ) : null}
          </div>
          {/* <div className="actions-pay flex aic justify-end">
            <div className="btn button">Execute Payroll</div>
            <div className="btn button ml-4">Paid</div>
          </div> */}
        </>
      ) : (
        <Loader />
      )}
      <Modal open={open} onClose={() => setOpen(false)}>
        <StatutoryFundsDetail setOpen={setOpen} data={statutoryFund} />
      </Modal>
      <Modal open={open2} onClose={() => setOpen2(false)}>
        <NetPayDetail setOpen2={setOpen2} data={netPayDetail} />
      </Modal>
      <Modal open={open3} onClose={() => setOpen3(false)}>
        <AddPayrollUsers setOpen3={setOpen3} listData={getSelectedTabData()} />
      </Modal>
    </div>
  );
};

export default PayrollDetail;
