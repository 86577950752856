import React from "react";

function ExportIcon(status) {
  const pathText = (`${status.status}` === 'Paid') ? "enabled" : "disabled"
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        className={`${pathText}`}
        d="M21 18H7V4h7V2H7a2 2 0 00-2 2v14a2 2 0 002 2h14c1.1 0 2-.9 2-2v-7h-2v7zM16 2v2h3.59l-9.83 9.83 1.41 1.41L21 5.41V9h2V2h-7z"
      ></path>
    </svg>
  );
}

export default ExportIcon;
