import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  SearchIcon,
  EditIcon,
  UserAddIcon,
  ActiveUserIcon,
} from "../../assets";
import Modal from "../../components/Modal";
import Loader from "../../components/Loader";
import UserRevokeAccess from "../../components/AdminSide/UserRevokeAccess";
import UserReRevokeAccess from "../../components/AdminSide/UserRe-revokeAccess";
import DataTable from "react-data-table-component";
import {
  useCreateUserMutation,
  useGetUsersListQuery,
} from "../../store/services/usersService";

const Users = () => {
  const {
    data: usersList,
    isFetching: isFetchingUsersList,
    isLoading,
  } = useGetUsersListQuery();
  const pageIs = "hr";
  const [open, setOpen] = useState(false);
  const [openReAccess, setOpenReAccess] = useState(false);
  const [revokeId, setRevokeId] = useState("");
  const [allData, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const searchFieldIs = [
    "userName",
    "firstName",
    "lastName",
    // "uId",
    "nationalId",
    "email",
    "department_id",
  ];
  const searchText = (target, value) => {
    return target.toLowerCase().indexOf(value) > -1 ? true : false;
  };
  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase();
    if (event.target.value !== "") {
      let result = [];
      result = allData.filter((data) => {
        let flag = false;
        for (let i = 0; i < searchFieldIs.length; i++) {
          const targetField = searchFieldIs[i];
          if (data[`${targetField}`]) {
            if (targetField === "department_id") {
              flag = searchText(data[`${targetField}`].name, value);
              if (flag) break;
            } else {
              flag = searchText(data[`${targetField}`], value);
              if (flag) break;
            }
          }
        }
        if (flag) return data;
      });
      setFilteredData(result);
    } else {
      setFilteredData(allData);
    }
  };

  useEffect(() => {
    if (!isFetchingUsersList && !isLoading) {
      setData(usersList?.data);
      setFilteredData(usersList?.data);
    }
  }, [usersList?.data]);

  const renderColumns = () => {
    return [
      {
        name: "First Name",
        sortable: true,
        selector: (row) => (
          <div
            style={{
              textDecoration: row.isRevoked ? "line-through" : "none",
              color: row.isRevoked ? "grey" : "black",
            }}
          >
            {row.firstName}
          </div>
        ),
      },
      {
        name: "Last Name",
        sortable: true,
        selector: (row) => (
          <div
            style={{
              textDecoration: row.isRevoked ? "line-through" : "none",
              color: row.isRevoked ? "grey" : "black",
            }}
          >
            {row.lastName}
          </div>
        ),
      },
      // {
      //     name: "Employee ID",
      //     sortable: true,
      //     selector: row => (
      //       <div style={{ textDecoration : row.isRevoked ? 'line-through' : 'none', color: row.isRevoked ? 'grey' : 'black' }}>
      //         {row.userId}
      //       </div>
      //     )
      // },
      {
        name: "National ID",
        sortable: true,
        selector: (row) => (
          <div
            style={{
              textDecoration: row.isRevoked ? "line-through" : "none",
              color: row.isRevoked ? "grey" : "black",
            }}
          >
            {row.nationalId}
          </div>
        ),
      },
      {
        name: "Email",
        sortable: true,
        selector: (row) => (
          <div
            style={{
              textDecoration: row.isRevoked ? "line-through" : "none",
              color: row.isRevoked ? "grey" : "black",
            }}
          >
            {row.email}
          </div>
        ),
      },
      {
        name: "Department",
        sortable: true,
        selector: (row) => (
          <div
            style={{
              textDecoration: row.isRevoked ? "line-through" : "none",
              color: row.isRevoked ? "grey" : "black",
            }}
          >
            {row?.department_id?.name}
          </div>
        ),
      },
      {
        name: "",
        button: true,
        cell: (row) => (
          <>
            <Link
              to={`/update-user/${row.uId}/${pageIs}`}
              className="icon cursor-pointer"
            >
              <EditIcon />
            </Link>
            {row.isRevoked === false ? (
              <div
                className="icon cursor-pointer"
                onClick={(e) => {
                  setOpen(true);
                  setRevokeId(row.uId);
                }}
              >
                <UserAddIcon />
              </div>
            ) : (
              <div
                className="icon cursor-pointer"
                onClick={(e) => {
                  setOpenReAccess(true);
                  setRevokeId(row.uId);
                }}
              >
                <ActiveUserIcon />
              </div>
            )}
          </>
        ),
      },
    ];
  };

  return (
    <div className="users-page felx">
      <div className="wrap wrapWidth flex flex-col">
        <div className="page-hdr flex justify-end">
          <div className="search-box flex aic">
            <input
              type="text"
              className="txt cleanbtn cursor-pointer"
              placeholder="Search here"
              onChange={(event) => handleSearch(event)}
            />
            <div className="icon flex aic jc">
              <SearchIcon />
            </div>
          </div>
          <Link
            to="/new-user"
            className="add-user flex aic jc ml-6 cursor-pointer"
          >
            <UserAddIcon />
          </Link>
        </div>
        {!isLoading && !isFetchingUsersList ? (
          <DataTable
            columns={renderColumns()}
            data={filteredData}
            responsive={true}
            pagination={true}
          />
        ) : (
          <Loader />
        )}
      </div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <UserRevokeAccess setOpen={setOpen} revokeId={revokeId} />
      </Modal>
      <Modal open={openReAccess} onClose={() => setOpenReAccess(false)}>
        <UserReRevokeAccess setOpen={setOpenReAccess} revokeId={revokeId} />
      </Modal>
    </div>
  );
};

export default Users;
