import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import { DropDownIcon } from "../../assets";
import Loader from "../Loader";
import {
  useFetchComponentQuery,
  useUpdateComponentMutation,
} from "../../store/services/componentService";

const UpdateComponent = () => {
  const navigate = useNavigate();
  const { uId } = useParams();
  const { data, isFetching } = useFetchComponentQuery(uId);

  const [hide, setHide] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState();
  const [statusData, setStatusData] = useState([
    { id: "deduction", title: "Deduction" },
    { id: "earning", title: "Earning" },
  ]);
  useEffect(() => {
    document.addEventListener("click", () => {
      setHide(false);
    });
  }, []);

  const [createComponent, setCreateComponent] = useState({
    name: "",
    type: "",
    ccType: "",
    amount: "",
    status: "",
    applicable: { CPF: "", SDL: "" },
  });

  const handleInput = (e) => {
    setCreateComponent({ ...createComponent, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (!isFetching) {
      setCreateComponent(data?.data);
    }
  }, [data?.data]);

  const [saveComponent, response] = useUpdateComponentMutation();
  const errors = response?.error?.data ? response?.error?.data.message : null;

  const createNewComponent = (e) => {
    // e.preventDefault();
    saveComponent({ createComponent, uId });
  };

  useEffect(() => {
    if (response?.isSuccess) {
      navigate("/component");
    }
  }, [response?.isSuccess]);

  return (
    <div className="new-department flex">
      <div className="wrap wrapWidth flex flex-col">
        {errors !== null && (
          <div className="flex bg-red-100 text-red-700 p-3  mb-2 rounded-md text-sm font-medium">
            {errors}
          </div>
        )}
        {!isFetching ? (
          <div className="flex flex-col">
            <div className="row">
              <div className="field flex flex-col">
                <div className="lbl">Name</div>
                <input
                  // id="name"
                  name="name"
                  value={createComponent.name}
                  onChange={handleInput}
                  type="text"
                  className="txt"
                  placeholder="Name"
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Type</div>
                <div className="dropDown flex aic flex-col rel ">
                  <div className="category flex aic">
                    <div
                      className="cbox cleanbtn flex aic rel pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        setHide(!hide);
                      }}
                    >
                      <div className="slt flex aic">
                        <div className="unit-name flex aic font s14 b4">
                          <span
                            className="unit-eng capitalize flex aic font s14 b4"
                            placeholder="Type"
                          >
                            {selectedCompany
                              ? selectedCompany
                              : createComponent.ccType}
                          </span>
                        </div>
                      </div>

                      <div>
                        <DropDownIcon />
                      </div>
                    </div>
                  </div>
                  <div className={`block flex aic abs ${hide ? "show" : ""}`}>
                    <div className="manue flex aic col anim">
                      {statusData.map((item, index) => (
                        <div
                          key={index}
                          className="slt flex aic"
                          onClick={(e) => {
                            setHide(!hide);
                            setSelectedCompany(item.title);
                            setCreateComponent({
                              ...createComponent,
                              ccType: item.id,
                            });
                          }}
                        >
                          <div className="unit-name flex aic font s14 b4">
                            <span className="unit-eng flex aic font s14 b4">
                              {item.title}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Amount</div>
                <input
                  id="amount"
                  name="amount"
                  value={createComponent.amount}
                  onChange={handleInput}
                  type="text"
                  className="txt"
                  placeholder="Amount"
                />
              </div>
              <div className="field flex items-end justify-start">
                <div className="radito-select flex  aic">
                  <div className="c555 font lbl">Percentage</div>
                  <button
                    type="button"
                    onClick={(e) => {
                      setCreateComponent({
                        ...createComponent,
                        type: "percentage",
                      });
                    }}
                    className={`cleanbtn radio-btn rel ${createComponent.type === "percentage" ? "on" : ""
                      }`}
                  />
                </div>
                <div className="radito-select flex aic">
                  <div className="c555 b4 font lbl">Fixed</div>
                  <button
                    type="button"
                    onClick={(e) => {
                      setCreateComponent({
                        ...createComponent,
                        type: "fixed",
                      });
                    }}
                    className={`cleanbtn radio-btn rel ${createComponent.type === "fixed" ? "on" : ""
                      }`}
                  />
                </div>
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Applied to</div>
                <div className="select-box flex aic">
                  <div className="box-tag">CPF</div>
                  <button
                    type="button"
                    onClick={(e) => {
                      setCreateComponent((prevComponent) => ({
                        ...prevComponent,
                        applicable: {
                          ...prevComponent.applicable,
                          CPF: !prevComponent.applicable.CPF,
                        },
                      }));
                    }}
                    className={`cleanbtn checkbox-btn rel ${createComponent.applicable.CPF ? "on" : ""
                      }`}
                  />
                </div>
                <div className="select-box flex aic">
                  <div className="box-tag">SDL</div>
                  <button
                    type="button"
                    onClick={(e) => {
                      setCreateComponent((prevComponent) => ({
                        ...prevComponent,
                        applicable: {
                          ...prevComponent.applicable,
                          SDL: !prevComponent.applicable.SDL,
                        },
                      }));
                    }}
                    className={`cleanbtn checkbox-btn rel ${createComponent.applicable.SDL ? "on" : ""
                      }`}
                  />
                </div>
              </div>
            </div>
            <div className="action flex aic justify-end">
              <div
                className="btn button"
                onClick={(e) => createNewComponent(e)}
              >
                {response.isLoading ? "Loading..." : "Create"}
              </div>
              {/* <input
            type="submit"
            value={data.isLoading ? "Loading..." : "Create"}
            className="btn button"
          /> */}
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default UpdateComponent;
