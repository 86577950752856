import React, { useState, useEffect, useInsertionEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Select from "react-select";
import Datetime from "react-datetime";
import Loader from "../../components/Loader";
import { DropDownIcon } from "../../assets";
import TagsInput from "../../components/TagsInput";

import {
  CalendarIcon,
  AddIcon,
  EditIcon,
  DelIcon,
  EyeOffIcon,
  EyeIcon,
} from "../../assets";

import { useFetchUserInfoQuery } from "../../store/services/generalService";
import {
  useUpdateUserMutation,
  useGetUsersListQuery,
  useFetchSingleUserQuery,
} from "../../store/services/usersService";
import {
  useGetQuery,
  useGetCountryQuery,
  useGetStateQuery,
  useGetCityQuery,
} from "../../store/services/generalService";

import { useGetDepartListQuery } from "../../store/services/departmentsService";
import { useGetComponentListQuery } from "../../store/services/componentService";
import { array } from "yup";

const UpdateUser = () => {
  const navigate = useNavigate();
  const { uId, page } = useParams();

  const { userRole, userToken, userOrgId, userId } = useSelector(
    (state) => state.authReducer
  );
  const { data: userInfo, isFetching } = useFetchUserInfoQuery(userId);

  const { data: usersList, isFetching: isFetchingUsersList } =
    useGetUsersListQuery();

  const { data: singleUserData, isFetching: singleUserDataFetching } =
    useFetchSingleUserQuery(uId ? uId : userId);
  // console.log("User data", singleUserData);

  const [inputReadOnly, setReadonly] = useState(page === "hr" ? false : true);
  const [step, setStep] = useState(1);
  const [hide, setHide] = useState(false);
  // const nextStep = () => {
  //   setStep(step + 1);
  //   document.documentElement.scrollTop = 0;
  // };
  // const prevStep = () => {
  //   setStep(step - 1);
  //   document.documentElement.scrollTop = 0;
  // };
  const [addBank, setAddBank] = useState(false);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    contactNo: "",
    nationalId: "",
    dateOfBirth: "",
    department_id: "",
    designation: "",
    reportTo: "",
    status: "",
    country: "",
    state: "",
    city: "",
    postalCode: "",
    address: [],
    roles: [],
    workdays: [],
    salary: [
      {
        effectiveDate: "",
        amount: "",
        type: "",
      },
    ],
    component: [
      {
        effectiveDate: "",
        component_id: "",
      },
    ],
    imigrationStatus: "",
    identificationNumber: "",
    issueDate: "",
    expiryDate: "",
    tier: "",
    skillCategory: "",
    sector: "",
    nationality: "",
    race: "",
    religion: "",
    groupContribution: "",
    cpfAccountNo: "",
    applicable: {
      SDL: false,
      FWL: false,
    },
    bank: {
      bankOf: "",
      accountName: "",
      accountNo: "",
    },
  });
  const [dateBirth, setDateBirth] = useState();
  const [issueDate, setIssueDate] = useState();
  const [expDate, setExpDate] = useState();
  const [salaryTypeVal, setSalaryTypeVal] = useState({
    display: "",
    value: "",
  });
  const [selectedCountry, setSelectedCountry] = useState({
    name: "",
    country_code: "",
  });
  const [selectedState, setSelectedState] = useState({
    name: "",
    country_code: "",
  });
  const [selectedCity, setSelectedCity] = useState({
    name: "",
    country_code: "",
  });
  const [selectedDepart, setSelectedDepart] = useState({
    name: "",
    _id: "",
  });
  const [selectedStatus, setSelectedStatus] = useState({
    display: "",
    value: "",
  });
  // const [selectedSector, setSelectedSector] = useState({
  //   display: "",
  //   value: "",
  // });
  const [selectedImmigration, setSelectedImmigration] = useState({
    display: "",
    value: "",
  });
  const [selectedTier, setSelectedTier] = useState({
    display: "",
    value: "",
  });
  const [selectedTierCategory, setSelectedTierCategory] = useState({
    display: "",
    value: "",
  });
  const [selectedRace, setSelectedRace] = useState({
    display: "",
    value: "",
  });
  const [selectedNationality, setSelectedNationality] = useState({
    name: "",
    country_code: "",
  });
  const [selectedReligion, setSelectedReligion] = useState({
    display: "",
    value: "",
  });
  const [selectedGroupContribution, setSelectedGroupContribution] = useState({
    display: "",
    value: "",
  });
  const [selectedBank, setSelectedBank] = useState({});
  const [reportTo, setReportTo] = useState();

  const {
    data: optionsList,
    isLoading: optionsListLoading,
    isFetching: optionsListFetching,
  } = useGetQuery();

  const {
    data: countries,
    isLoading: countriesLoading,
    isFetching: countriesFetching,
  } = useGetCountryQuery();

  const {
    data: states,
    isLoading: statesLoading,
    isFetching: statesFetching,
  } = useGetStateQuery(selectedCountry?.country_code);

  const {
    data: cites,
    isLoading: citesLoading,
    isFetching: citesFetching,
  } = useGetCityQuery({
    countryCode: selectedCountry?.country_code,
    stateCode: selectedState?.state_code,
  });

  const {
    data: departmentsList,
    isLoading: departmentLoading,
    isFetching: departmentFetching,
  } = useGetDepartListQuery();

  const [departList, setDepartList] = useState();

  useEffect(() => {
    setDepartList(
      departmentsList?.data.filter((el, i) => el.status == "active")
    );
  }, [departmentsList?.data]);

  const {
    data: componentList,
    isLoading: componentLoading,
    isFetching: componentFetching,
  } = useGetComponentListQuery();

  const handleInput = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleBankInput = (e) => {
    setUserData((prevState) => ({
      ...prevState,
      bank: {
        ...prevState.bank,
        [e.target.name]: e.target.value,
      },
    }));
    // setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  //Handle Address
  const handleAddress = (index) => (e) => {
    let newArr = [...userData?.address];
    newArr[index] = e.target.value;
    setUserData({ ...userData, address: newArr });
  };

  // Handle remove Address
  let newAddressArr = [...userData?.address];
  const removeAddress = (index) => {
    newAddressArr = newAddressArr.filter((el, i) => i !== index);
    setUserData({ ...userData, address: newAddressArr });
  };

  // Handle Roles
  const [roleSuperAdmin, setRoleSuperAdmin] = useState(false);
  const [roleHrAdmin, setRoleHrAdmin] = useState(false);
  const [roleUser, setRoleUser] = useState(false);
  const [workDaysSet, setWorkingDay] = useState(false);
  const [rolesList, setRolesList] = useState(
    userData.roles ? userData.roles : []
  );

  function removeTag(index) {
    let newRolesList = rolesList.filter(function (item) {
      return item !== index;
    });
    setRolesList(newRolesList);
    // setUserData({ ...userData, roles: newRolesList });
  }

  useEffect(() => {
    setRolesList(userData.roles ? userData.roles : []);
  }, [userData.roles]);

  useEffect(() => {
    setWorkingDay(userData.workdays);
  }, [userData, userRole]);
  // Handle WorksDays
  const handleWorkdays = (e) => {
    // Destructuring
    const { value, checked } = e.target;

    // Case 1 : The user checks the box
    if (checked) {
      let finalSet = [];
      let duplicate = false;
      workDaysSet.forEach((element) => {
        finalSet.push(element);
        if (value === element) {
          duplicate = true;
        }
      });
      if (duplicate === false) {
        finalSet.push(value);
      }
      setUserData({ ...userData, workdays: finalSet });
    }

    // Case 2  : The user unchecked the box
    else {
      let newWorkDaysList = workDaysSet.filter(function (item) {
        return item !== value;
      });
      setUserData({ ...userData, workdays: newWorkDaysList });
    }
  };

  const daysList = ["Monday", "Wednesday", "Friday", "Sunday"];

  const DayChecked = (dayValue) => {
    var newArray = workDaysSet?.filter(function (el) {
      return el == dayValue;
    });
    if (newArray[0] === dayValue) {
      return true;
    } else {
      return false;
    }
  };

  const [cSalary, setCSalary] = useState(false);
  // Handle Salary
  const removeSalaryRow = (index) => {
    setUserData((prev) => {
      const newUserData = { ...prev };
      newUserData.salary = newUserData.salary.filter((el, i) => i !== index);
      return newUserData;
    });
  };

  // Handle Type in Salary table
  const addSalaryTypeRow = (val, index) => {
    setUserData((prev) => {
      let newUserData = { ...prev };
      if (newUserData.salary) {
        newUserData.salary[index].type = val?.value;
      }
      return newUserData;
    });
  };

  // useEffect(() => {
  //   setUserData({ ...userData, salary: salaryArr });
  // }, []);

  // Handle Component
  const removeComponentRow = (index) => {
    setUserData((prev) => {
      let newUserData = { ...prev };
      newUserData.component = newUserData.component.filter(
        (el, i) => i !== index
      );
      return newUserData;
    });
  };

  // Handle Name in Component table
  const addComponentNameRow = (val, index) => {
    let componentData = JSON.parse(JSON.stringify(userData.component));

    if (componentData[index]) {
      componentData[index].component_id = String(val?.value);
    }

    setUserData((prev) => ({
      ...prev,
      component: componentData,
    }));
  };

  const ChooseBank = (val) => {
    setUserData({ ...ChooseBank, bank: val });
  };

  // Handle applicable
  const applicableObj = userData.applicable;
  const handleApplicable = (e) => {
    const { value, checked } = e.target;

    setUserData((prevUserData) => {
      const newApplicableObj = { ...prevUserData.applicable };

      // Case 1: The user checks the box
      if (checked) {
        newApplicableObj[value] = true;
      }
      // Case 2: The user unchecked the box
      else {
        newApplicableObj[value] = false;
      }

      return {
        ...prevUserData,
        applicable: newApplicableObj,
      };
    });
  };

  useEffect(() => {
    setUserData({ ...userData, country: selectedCountry?.name });
  }, [selectedCountry]);
  useEffect(() => {
    setUserData({ ...userData, nationality: selectedNationality?.name });
  }, [selectedNationality]);
  useEffect(() => {
    setUserData({ ...userData, race: selectedRace?.value });
  }, [selectedRace]);
  useEffect(() => {
    setUserData({ ...userData, religion: selectedReligion?.value });
  }, [selectedReligion]);
  useEffect(() => {
    setUserData({ ...userData, state: selectedState?.name });
  }, [selectedState]);
  useEffect(() => {
    setUserData({ ...userData, city: selectedCity?.name });
  }, [selectedCity]);
  useEffect(() => {
    setUserData({ ...userData, department_id: selectedDepart?._id });
  }, [selectedDepart]);

  useEffect(() => {
    setUserData({ ...userData, status: selectedStatus?.value });
  }, [selectedStatus]);

  useEffect(() => {
    setUserData({ ...userData, imigrationStatus: selectedImmigration?.value });
  }, [selectedImmigration]);

  useEffect(() => {
    setUserData({
      ...userData,
      groupContribution: selectedGroupContribution?.value,
    });
  }, [selectedGroupContribution]);

  useEffect(() => {
    setUserData((prevState) => ({
      ...prevState,
      tier: selectedTier.value,
    }));
  }, [selectedTier]);

  useEffect(() => {
    setUserData((prevState) => ({
      ...prevState,
      skillCategory: selectedTierCategory.value,
    }));
  }, [selectedTierCategory]);

  useEffect(() => {
    setUserData((prevState) => ({
      ...prevState,
      bank: {
        ...prevState.bank,
        bankOf: selectedBank?.value,
      },
    }));
  }, [selectedBank]);

  useEffect(() => {
    let result = reportTo?.length > 0 ? reportTo.map((a) => a._id) : [];
    setUserData({ ...userData, reportTo: result });
  }, [reportTo]);

  // useEffect(() => {
  //   setUserData({ ...userData, sector: selectedSector?.value });
  // }, [selectedSector]);

  const [updateUser, response] = useUpdateUserMutation();
  const errors = response?.error?.data ? response?.error?.data.message : null;
  const [inputFieldError, setInputFieldError] = useState(null);

  const updateUserData = (e) => {
    e.preventDefault();
    userData.roles = rolesList;
    updateUser(userData);
    // updateUser({ userData, uId });
  };

  useEffect(() => {
    if (response?.isSuccess) {
      navigate("/users");
    }
  }, [response?.isSuccess]);

  useEffect(() => {
    if (!singleUserDataFetching) {
      setUserData(singleUserData?.data);
      setSelectedDepart(singleUserData?.data?.department_id);
      setDateBirth(new Date(singleUserData?.data?.dateOfBirth)?.getTime());
      setIssueDate(new Date(singleUserData?.data?.issueDate)?.getTime());
      setExpDate(new Date(singleUserData?.data?.expiryDate)?.getTime());

      var myFilterStatus = optionsList?.data?.userStatuses?.filter(function (
        el
      ) {
        return el.value == singleUserData?.data?.status
          ? el.value == singleUserData?.data?.statussingleUserData
          : "";
      });

      setSelectedStatus({
        display: myFilterStatus?.length
          ? myFilterStatus[0].display
          : singleUserData?.data?.status,
        value: singleUserData?.data?.status,
      });

      setSelectedCountry({
        name: singleUserData?.data?.country,
        country_code: singleUserData?.data?.country,
      });
      setSelectedState({
        name: singleUserData?.data?.state,
        country_code: singleUserData?.data?.state,
      });
      setSelectedCity({
        name: singleUserData?.data?.city,
        country_code: singleUserData?.data?.city,
      });

      var myFilterImigrationName =
        optionsList?.data?.imigrationStatusList?.filter(function (el) {
          return el.value == singleUserData?.data?.imigrationStatus
            ? el.value == singleUserData?.data?.imigrationStatus
            : "";
        });
      setSelectedImmigration({
        display: myFilterImigrationName
          ? myFilterImigrationName[0]?.display
          : singleUserData?.data?.imigrationStatus,
        value: singleUserData?.data?.imigrationStatus,
      });

      setSelectedNationality({
        name: singleUserData?.data?.nationality,
        country_code: singleUserData?.data?.nationality,
      });
      const tierFilterData = optionsList?.data?.tierList?.filter(function (el) {
        return el.value === singleUserData?.data?.tier
          ? el.value === singleUserData?.data?.tier
          : "";
      });
      setSelectedTier({
        display: tierFilterData
          ? tierFilterData[0]?.display
          : singleUserData?.data?.tier,
        value: singleUserData?.data?.tier,
      });

      const tierSkillFilterData = optionsList?.data?.skillCategoryList?.filter(
        function (el) {
          return el.value === singleUserData?.data?.skillCategory
            ? el.value === singleUserData?.data?.skillCategory
            : "";
        }
      );
      setSelectedTierCategory({
        display: tierSkillFilterData
          ? tierSkillFilterData[0]?.display
          : singleUserData?.data?.skillCategory,
        value: singleUserData?.data?.skillCategory,
      });

      var myFilterRace = optionsList?.data?.raceList?.filter(function (el) {
        return el.value === singleUserData?.data?.race
          ? el.value === singleUserData?.data?.race
          : "";
      });

      setSelectedRace({
        display: myFilterRace
          ? myFilterRace[0]?.display
          : singleUserData?.data?.race,
        value: singleUserData?.data?.race,
      });

      setSelectedGroupContribution({
        display: singleUserData?.data?.groupContribution,
        value: singleUserData?.data?.groupContribution,
      });

      var myFilterReligion = optionsList?.data?.religionList?.filter(function (
        el
      ) {
        return el.value === singleUserData?.data?.religion
          ? el.value === singleUserData?.data?.religion
          : "";
      });

      setSelectedReligion({
        display: myFilterReligion
          ? myFilterReligion[0]?.display
          : singleUserData?.data?.religion,
        value: singleUserData?.data?.religion,
      });

      var myFilterBankName = optionsList?.data?.bankList?.filter(function (el) {
        return el.value == singleUserData?.data?.bank?.bankOf
          ? el.value == singleUserData?.data?.bank?.bankOf
          : "";
      });
      setSelectedBank({
        display: myFilterBankName
          ? myFilterBankName[0]?.display
          : singleUserData?.data?.bank.bankOf,
        value: singleUserData?.data?.bank.bankOf,
      });

      setReportTo(singleUserData?.data?.reportTo);
    }
  }, [singleUserDataFetching]);

  const getSelectedComponent = (_id) => {
    return componentList?.data?.find((x) => x._id === _id);
  };

  const getValueComponent = (component_id) => {
    const selectedComponent = componentList?.data?.find(
      (x) => x._id === component_id
    );
    return {
      label: selectedComponent?.name,
      value: selectedComponent?._id,
    };
  };

  const getValueSalary = (type) => {
    const selectedSalary = optionsList?.data?.salaryType?.find(
      (x) => x.value === type
    );
    return {
      label: selectedSalary?.display,
      value: selectedSalary?.value,
    };
  };
  const updateSalaryData = (index, field, value) => {
    setUserData((prevUserData) => {
      const newSalaryData = [...prevUserData.salary];
      newSalaryData[index] = {
        ...newSalaryData[index],
        [field]: value,
      };

      const newState = {
        ...prevUserData,
        salary: newSalaryData,
      };

      console.log("New state:", newState);

      return newState;
    });
  };

  const nextStep = () => {
    document.documentElement.scrollTop = 0;
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
    document.documentElement.scrollTop = 0;
  };

  // Validation function for Step 1
  const validateStep1 = () => {
    const {
      firstName,
      lastName,
      userName,
      email,
      contactNo,
      nationalId,
      dateOfBirth,
      department_id,
      designation,
      reportTo,
      status,
      password,
    } = userData;

    if (
      firstName?.trim() !== "" &&
      userName?.trim() !== "" &&
      email?.trim() !== "" &&
      contactNo?.trim() !== "" &&
      nationalId?.trim() !== "" &&
      dateOfBirth !== "" &&
      department_id !== "" &&
      reportTo?.length > 0 &&
      status?.trim() !== "" &&
      password?.trim() !== ""
    ) {
      nextStep();
      setInputFieldError(null);
    } else {
      document.documentElement.scrollTop = 0;
      setInputFieldError("Please enter data in all required * filed");
    }
  };

  const validateStep2 = () => {
    const { country, state, city, postalCode } = userData;
    if (
      country.trim() !== "" &&
      state.trim() !== "" &&
      city.trim() !== "" &&
      postalCode.trim() !== ""
    ) {
      nextStep();
      setInputFieldError(null);
    } else {
      document.documentElement.scrollTop = 0;
      setInputFieldError("Please enter data in all required * filed");
    }
  };

  const validateStep3 = () => {
    const { roles } = userData;

    // Check if all required fields in Step 1 are filled
    if (roles.length > 0) {
      nextStep();
      setInputFieldError(null);
    } else {
      document.documentElement.scrollTop = 0;
      setInputFieldError("Please enter data in all required * filed");
    }
  };

  const validateStep4 = () => {
    const { workdays, salary, component } = userData;

    // Check if all required fields in Step 1 are filled
    if (
      workdays?.length > 0 ||
      (workdays?.length === 0 && salary?.length > 0) ||
      salary?.length === 0
    ) {
      nextStep();
      setInputFieldError(null);
    } else {
      document.documentElement.scrollTop = 0;
      setInputFieldError("Please enter data in all required * filed");
    }
  };

  const validateStep5 = () => {
    const {
      imigrationStatus,
      identificationNumber,
      issueDate,
      expiryDate,
      skillCategory,
      nationality,
      race,
      religion,
      groupContribution,
      cpfAccountNo,
      applicable,
    } = userData;

    // Check if all required fields in Step 1 are filled
    if (
      imigrationStatus.trim() !== "" &&
      identificationNumber.trim() &&
      nationality.trim() &&
      race.trim() &&
      religion.trim() &&
      groupContribution.trim() &&
      cpfAccountNo.trim()
    ) {
      nextStep();
      setInputFieldError(null);
    } else {
      document.documentElement.scrollTop = 0;
      setInputFieldError("Please enter data in all required * filed");
    }
  };

  const handleNextButton = () => {
    if (step === 1) {
      validateStep1();
    } else if (step === 2) {
      validateStep2();
    } else if (step === 3) {
      validateStep3();
    } else if (step === 4) {
      validateStep4();
    } else if (step === 5) {
      validateStep5();
    }
  };
  console.log("userData after rendering:", userData);
  return (
    <div className="create-new-user-page flex">
      <div className="wrap wrapWidth flex flex-col">
        <div className="page-hdr flex">
          <div className="steps-blk flex aic justify-between rel">
            <div className="line abs"></div>
            <div
              className={`step-btn flex aic jc cursor-pointer ${
                step === 1 ||
                step === 2 ||
                step === 3 ||
                step === 4 ||
                step === 5 ||
                step === 6
                  ? "active"
                  : ""
              }`}
            >
              General Information
            </div>
            <div
              className={`step-btn flex aic jc cursor-pointer ${
                step === 2 ||
                step === 3 ||
                step === 4 ||
                step === 5 ||
                step === 6
                  ? "active"
                  : ""
              }`}
            >
              Contact Information
            </div>
            <div
              className={`step-btn flex aic jc cursor-pointer ${
                step === 3 || step === 4 || step === 5 || step === 6
                  ? "active"
                  : ""
              }`}
            >
              Roles & Permissions
            </div>
            <div
              className={`step-btn flex aic jc cursor-pointer ${
                step === 4 || step === 5 || step === 6 ? "active" : ""
              }`}
            >
              Work days & Compensations
            </div>
            <div
              className={`step-btn flex aic jc cursor-pointer ${
                step === 5 || step === 6 ? "active" : ""
              }`}
            >
              Immigration Details
            </div>
            <div
              className={`step-btn flex aic jc cursor-pointer ${
                step === 6 ? "active" : ""
              }`}
            >
              Bank Information
            </div>
          </div>
        </div>
        {errors !== null && (
          <div className="error-message flex bg-red-100 text-red-700 p-3  mb-2 rounded-md text-sm font-medium">
            {errors}
          </div>
        )}
        {inputFieldError !== null && (
          <div className="error-message mt-5 flex bg-red-100 text-red-700 p-3  mb-2 rounded-md text-sm font-medium">
            {inputFieldError}
          </div>
        )}
        {!singleUserDataFetching ? (
          <div className="content">
            {step === 1 ? (
              <div className="general-info-camp flex flex-col flex-">
                <div className="wrap-info flex flex-col">
                  <div className="page-title mb-5">General Information</div>
                  <div className="row">
                    <div className="field flex flex-col">
                      <div className="lbl">First name</div>
                      <input
                        name="firstName"
                        type="text"
                        className="txt cleanbtn"
                        placeholder="First name"
                        value={userData.firstName}
                        onChange={handleInput}
                      />
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl">Last name</div>
                      <input
                        name="lastName"
                        type="text"
                        className="txt cleanbtn"
                        placeholder="Last name"
                        value={userData.lastName}
                        onChange={handleInput}
                      />
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl">Username</div>
                      <input
                        name="userName"
                        type="text"
                        className="txt cleanbtn"
                        placeholder="Username"
                        value={userData.userName}
                        onChange={handleInput}
                      />
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl">Email</div>
                      <input
                        name="email"
                        type="text"
                        className="txt cleanbtn"
                        placeholder="Email"
                        value={userData.email}
                        onChange={handleInput}
                      />
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl">Contact no</div>
                      <input
                        name="contactNo"
                        type="text"
                        className="txt cleanbtn"
                        placeholder="Contact no"
                        value={userData.contactNo}
                        onChange={handleInput}
                      />
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl">National id</div>
                      <input
                        name="nationalId"
                        type="text"
                        className="txt cleanbtn"
                        placeholder="National id"
                        value={userData.nationalId}
                        onChange={handleInput}
                      />
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl">Date of birth</div>
                      <div className="date-picker flex aic jc">
                        <Datetime
                          closeOnSelect={true}
                          value={
                            dateBirth
                              ? new Date(dateBirth).getTime()
                              : new Date().getTime()
                          }
                          onChange={(value) => {
                            {
                              setDateBirth(new Date(value).getTime());
                              setUserData({
                                ...userData,
                                dateOfBirth: new Date(value).getTime(),
                              });
                            }
                          }}
                          timeFormat={false}
                          dateFormat="MMMM Do, YYYY"
                          className="start-date cleanbtn"
                        />
                        <div className="ico">
                          <CalendarIcon />
                        </div>
                      </div>
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl">Department</div>
                      <Select
                        defaultValue={selectedDepart}
                        value={selectedDepart}
                        onChange={setSelectedDepart}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option._id}
                        options={departList}
                        name="department_id"
                        className="select"
                        placeholder="Department"
                        isDisabled={inputReadOnly}
                      />
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl">Designation</div>
                      <input
                        name="designation"
                        type="text"
                        className="txt cleanbtn"
                        placeholder="Designation"
                        value={userData.designation}
                        onChange={handleInput}
                        disabled={inputReadOnly}
                      />
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl">Report to</div>
                      <Select
                        defaultValue={reportTo}
                        value={reportTo}
                        onChange={setReportTo}
                        isMulti
                        getOptionLabel={(option) => option.firstName}
                        getOptionValue={(option) => option.firstName}
                        options={usersList?.data}
                        name="reportTo"
                        className="select"
                        placeholder="Report To"
                        isDisabled={inputReadOnly}
                      />
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl">Status</div>
                      <Select
                        defaultValue={selectedStatus}
                        value={selectedStatus}
                        onChange={setSelectedStatus}
                        getOptionLabel={(option) => option.display}
                        getOptionValue={(option) => option.value}
                        options={optionsList?.data?.userStatuses}
                        name="status"
                        className="select"
                        placeholder="Status"
                        isDisabled={inputReadOnly}
                      />
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl">
                        Password <span className="strick">*</span>
                      </div>
                      <div className="password-field txt flex items-center w-full">
                        <input
                          name="password"
                          type={hide ? "text" : "password"}
                          className=" cleanbtn w-full"
                          placeholder="Password"
                          value={userData.password}
                          onChange={handleInput}
                        />
                        <div
                          className="eye-icon flex items-center justify-center cursor-pointer"
                          onClick={(e) => setHide(!hide)}
                        >
                          {hide ? <EyeOffIcon /> : <EyeIcon />}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : step === 2 ? (
              <div className="general-info-camp flex flex-col">
                <div className="wrap-info flex flex-col">
                  <div className="page-title mb-5">Contact Information</div>
                  <div className="row">
                    <div className="field flex flex-col">
                      <div className="lbl">Country</div>
                      <Select
                        defaultValue={selectedCountry}
                        value={selectedCountry}
                        onChange={setSelectedCountry}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.country_code}
                        options={countries?.data}
                        className="select"
                        id="country"
                        name="country"
                        placeholder="Select Country"
                      />
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl">State/Region</div>
                      <Select
                        defaultValue={selectedState}
                        value={selectedState}
                        onChange={setSelectedState}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.state_code}
                        options={states?.data}
                        className="select"
                        placeholder="State/Region"
                      />
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl">City</div>
                      <Select
                        defaultValue={selectedCity}
                        value={selectedCity}
                        onChange={setSelectedCity}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.state_code}
                        options={cites?.data}
                        className="select"
                        placeholder="City"
                      />
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl">Postal Code</div>
                      <input
                        name="postalCode"
                        type="text"
                        className="txt cleanbtn"
                        placeholder="Postal Code"
                        value={userData.postalCode}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="flex items-center py-5">
                      <div className="lbl mr-5">Add Address</div>
                      <div
                        className="ico hover:cursor-pointer"
                        onClick={(e) => {
                          setUserData({
                            ...userData,
                            address: [...userData.address, ""],
                          });
                        }}
                      >
                        <AddIcon />
                      </div>
                    </div>
                    <div className="row">
                      {userData?.address?.length > 0 ? (
                        <>
                          {userData?.address.map((item, index) => (
                            <div key={index} className="field flex flex-col">
                              <div className="lbl">Address {index + 1}</div>{" "}
                              <div className="bd rounded-md flex items-center">
                                <input
                                  type="text"
                                  name="address"
                                  className="w-full p-2 mx-1"
                                  value={item}
                                  placeholder="Address"
                                  onChange={handleAddress(index)}
                                />
                                <div
                                  className="cross-icon cursor-pointer"
                                  onClick={(e) => removeAddress(index)}
                                >
                                  X
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            ) : step === 3 ? (
              <div className="general-info-camp flex flex-col">
                <div className="wrap-info flex flex-col">
                  <div className="page-title mb-5">Roles</div>
                  <div className="row">
                    <div className="field flex flex-col">
                      <div className="lbl">Roles</div>
                      {/* <div className="item flex aic mb-2">
                        <button
                          type="button"
                          //   onClick={(e) => {
                          //     // setRoleSuperAdmin(!roleSuperAdmin);
                          //     if (roleSuperAdmin !== true) {
                          //       rolesList.push("superAdmin");
                          //     } else {
                          //       removeTag("superAdmin");
                          //     }
                          //   }}
                          className={`cleanbtn cursor-not-allowed important:not-allowed checkbox-btn rel ${
                            roleSuperAdmin ||
                            userData.roles[0] === "superAdmin" ||
                            userData.roles[1] === "superAdmin" ||
                            userData.roles[2] === "superAdmin"
                              ? "on"
                              : ""
                          }`}
                        />
                        <div className="check-box-lbl s14 ml-4">
                          Super admin
                        </div>
                      </div> */}
                      <div className="item flex aic mb-2">
                        <button
                          type="button"
                          onClick={(e) => {
                            // console.log("set role hrAdmin");
                            setRoleHrAdmin(!roleHrAdmin);
                            if (roleHrAdmin !== true) {
                              setRolesList([...rolesList, "hrAdmin"]);
                              // rolesList.push("hrAdmin");
                            } else {
                              setUserData((prevUserData) => ({
                                ...prevUserData,
                                roles: [],
                              }));
                            }
                          }}
                          disabled={inputReadOnly}
                          className={`cleanbtn checkbox-btn rel ${
                            roleHrAdmin ||
                            userData.roles[0] === "hrAdmin" ||
                            userData.roles[1] === "hrAdmin" ||
                            userData.roles[2] === "hrAdmin"
                              ? "on"
                              : ""
                          }`}
                        />
                        <div className="check-box-lbl s14 ml-4">HR admin</div>
                      </div>
                      <div className="item flex aic">
                        <button
                          type="button"
                          onClick={(e) => {
                            // console.log("set role user");
                            setRoleUser(!roleUser);
                            if (roleUser !== true) {
                              setRolesList([...rolesList, "user"]);
                              // rolesList.push("user");
                            } else {
                              setUserData((prevUserData) => ({
                                ...prevUserData,
                                roles: [],
                              }));
                            }
                          }}
                          disabled={inputReadOnly}
                          className={`cleanbtn checkbox-btn rel ${
                            roleUser ||
                            userData.roles[0] === "user" ||
                            userData.roles[1] === "user" ||
                            userData.roles[2] === "user"
                              ? "on"
                              : ""
                          }`}
                        />
                        <div className="check-box-lbl s14 ml-4">User</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : step === 4 ? (
              <div className="working-days-camp flex flex-col">
                <div className="wrap-info flex flex-col">
                  <div className="page-title mb-5">Work Days</div>
                  <div className="row">
                    <div className="field flex flex-col">
                      <div className="lbl">Work Days</div>
                      <div className="week-days flex aic mb-8">
                        {[
                          "Monday",
                          "Tuesday",
                          "Wednesday",
                          "Thursday",
                          "Friday",
                          "Saturday",
                          "Sunday",
                        ].map((day, index) => (
                          <div className="item flex aic mb-2 mr-7" key={index}>
                            <input
                              type="checkbox"
                              name="workdays"
                              disabled={inputReadOnly}
                              value={`${day}`}
                              onChange={handleWorkdays}
                              checked={
                                userData?.workdays?.filter((x) => x === day)
                                  .length > 0
                                  ? true
                                  : false
                              }
                              className="check-box mr-2 h-5 w-5 cursor-pointer"
                            />
                            <div className="check-box-lbl s16">{day}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  {/* Compensations */}
                  <div className="flex flex-col">
                    <div className="page-title mb-5">Compensations</div>
                    <div className="row">
                      <div className="field flex flex-col">
                        <div className="item flex aic mb-2 mr-7">
                          <div className="lbl mr-2">Salary</div>

                          {inputReadOnly === false && (
                            <div
                              className="icon flex aic jc cursor-pointer"
                              onClick={(e) => {
                                setUserData({
                                  ...userData,
                                  salary: [
                                    ...userData.salary,
                                    {
                                      effectiveDate: "",
                                      amount: null,
                                      type: "",
                                    },
                                  ],
                                });
                              }}
                            >
                              <AddIcon />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="table-blk flex">
                      <div className="tbl flex flex-col">
                        <div className="tbl-row flex aic">
                          <div className="tbl-col flex flex-1">
                            <div className="col-item">Effective Date</div>
                          </div>
                          <div className="tbl-col flex flex-1">
                            <div className="col-item">Amount</div>
                          </div>
                          <div className="tbl-col flex flex-1">
                            <div className="col-item">Type</div>
                          </div>
                          <div className="tbl-col flex">
                            <div className="col-item"></div>
                          </div>
                        </div>
                        {userData.salary?.length &&
                          userData.salary?.map((item, index) => (
                            <div key={index} className="tbl-row flex aic">
                              <div className="tbl-col flex flex-1">
                                <div className="date-picker flex aic jc">
                                  {inputReadOnly === false ? (
                                    <Datetime
                                      closeOnSelect={true}
                                      // value={
                                      //   dateBirth ? dateBirth : new Date().getTime()
                                      // }
                                      onChange={(value) => {
                                        setUserData((prev) => {
                                          const newUserData = { ...prev };
                                          newUserData.salary[
                                            index
                                          ].effectiveDate = new Date(
                                            value
                                          ).getTime();
                                          return newUserData;
                                        });
                                      }}
                                      value={new Date(item.effectiveDate)}
                                      name="effectiveDate"
                                      timeFormat={false}
                                      dateFormat="MMMM Do, YYYY"
                                      className="start-date cleanbtn"
                                    />
                                  ) : (
                                    <Datetime
                                      closeOnSelect={true}
                                      // value={
                                      //   dateBirth ? dateBirth : new Date().getTime()
                                      // }
                                      value={new Date(item.effectiveDate)}
                                      name="effectiveDate"
                                      timeFormat={false}
                                      dateFormat="MMMM Do, YYYY"
                                      className="start-date cleanbtn"
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="tbl-col flex flex-1">
                                <input
                                  type="text"
                                  name="amount"
                                  value={item.amount}
                                  // onChange={(e) =>
                                  //   setUserData((prev) => {
                                  //     const newUserData = { ...prev };
                                  //     newUserData.salary[index].amount =
                                  //       e.target.value;
                                  //     return newUserData;
                                  //   })
                                  // }
                                  onChange={(e) =>
                                    updateSalaryData(
                                      index,
                                      "amount",
                                      e.target.value
                                    )
                                  }
                                  disabled={inputReadOnly}
                                  className="col-item cleanbtn row-txt"
                                  placeholder="Amount"
                                />
                              </div>
                              <div className="tbl-col flex flex-1">
                                <Select
                                  value={getValueSalary(item.type)}
                                  defaultValue={getValueSalary(item.type)}
                                  onChange={(e) => addSalaryTypeRow(e, index)}
                                  options={optionsList?.data?.salaryType?.map(
                                    (x) => ({
                                      label: x.display,
                                      value: x.value,
                                    })
                                  )}
                                  isDisabled={inputReadOnly}
                                  name="type"
                                  className="select w-full"
                                  placeholder="Type"
                                />
                              </div>
                              <div className="tbl-col flex">
                                <div className="col-item flex aic jc">
                                  {/* <div className="icon cursor-pointer">
                                        <EditIcon />
                                      </div> */}
                                  {inputReadOnly === false && (
                                    <div
                                      className="icon cursor-pointer"
                                      onClick={(e) => removeSalaryRow(index)}
                                    >
                                      <DelIcon />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  {/* Components */}
                  <div className="flex flex-col mt-10">
                    <div className="page-title mb-5">Components</div>
                    <div className="row">
                      <div className="field flex flex-col">
                        <div className="item flex aic mb-2 mr-7">
                          {inputReadOnly === false && (
                            <>
                              <div className="lbl mr-2">Add</div>
                              <div
                                className="icon flex aic jc cursor-pointer"
                                onClick={(e) => {
                                  setUserData({
                                    ...userData,
                                    component: [
                                      ...userData.component,
                                      {
                                        effectiveDate: "",
                                        component_id: "",
                                      },
                                    ],
                                  });
                                }}
                              >
                                <AddIcon />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="table-blk flex">
                      <div className="tbl flex flex-col">
                        <div className="tbl-row flex aic">
                          <div className="tbl-col flex flex-1">
                            <div className="col-item">Name</div>
                          </div>
                          <div className="tbl-col flex flex-1">
                            <div className="col-item">Effective Date</div>
                          </div>
                          <div className="tbl-col flex flex-1">
                            <div className="col-item">Amount</div>
                          </div>
                          <div className="tbl-col flex flex-1">
                            <div className="col-item">Type</div>
                          </div>
                          <div className="tbl-col flex">
                            <div className="col-item"></div>
                          </div>
                        </div>
                        {userData?.component?.length > 0 &&
                          userData?.component?.map((item, index) => (
                            <div key={index} className="tbl-row flex aic">
                              <div className="tbl-col flex flex-1">
                                <Select
                                  value={getValueComponent(
                                    item.component_id?._id ?? item.component_id
                                  )}
                                  defaultValue={getValueComponent(
                                    item.component_id?._id ?? item.component_id
                                  )}
                                  onChange={(e) => {
                                    addComponentNameRow(e, index);
                                  }}
                                  options={componentList?.data?.map((x) => ({
                                    value: x._id,
                                    label: x.name,
                                  }))}
                                  name="name"
                                  className="select w-4/5"
                                  placeholder="Name"
                                />
                              </div>

                              <div className="tbl-col flex flex-1">
                                <div className="date-picker flex aic jc">
                                  {inputReadOnly === false ? (
                                    <Datetime
                                      closeOnSelect={true}
                                      value={new Date(item.effectiveDate)}
                                      onChange={(value) => {
                                        {
                                          setUserData((prev) => {
                                            let newUserData = { ...prev };
                                            newUserData.component[
                                              index
                                            ].effectiveDate = new Date(
                                              value
                                            ).getTime();
                                            return newUserData;
                                          });
                                        }
                                      }}
                                      name="effectiveDate"
                                      timeFormat={false}
                                      dateFormat="MMMM Do, YYYY"
                                      className="start-date cleanbtn"
                                    />
                                  ) : (
                                    <Datetime
                                      closeOnSelect={true}
                                      value={new Date(item.effectiveDate)}
                                      name="effectiveDate"
                                      timeFormat={false}
                                      dateFormat="MMMM Do, YYYY"
                                      className="start-date cleanbtn"
                                    />
                                  )}
                                </div>
                              </div>

                              <div className="tbl-col flex flex-1">
                                <input
                                  type="text"
                                  name="amount"
                                  value={
                                    getSelectedComponent(
                                      item.component_id?._id ??
                                        item.component_id
                                    )?.amount ?? 0
                                  }
                                  disabled
                                  className="col-item cleanbtn row-txt"
                                  placeholder="Amount"
                                  readOnly={inputReadOnly}
                                />
                              </div>
                              <div className="tbl-col flex flex-1">
                                <input
                                  type="text"
                                  name="type"
                                  value={
                                    getSelectedComponent(
                                      item.component_id?._id ??
                                        item.component_id
                                    )?.type ?? ""
                                  }
                                  disabled
                                  className="col-item cleanbtn row-txt"
                                  placeholder="Type"
                                  readOnly={inputReadOnly}
                                />
                              </div>
                              <div className="tbl-col flex">
                                <div className="col-item flex aic jc">
                                  {/* <div className="icon cursor-pointer">
                                    <EditIcon />
                                  </div> */}
                                  {inputReadOnly === false && (
                                    <div
                                      className="icon cursor-pointer"
                                      onClick={(e) => removeComponentRow(index)}
                                    >
                                      <DelIcon />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : step === 5 ? (
              <div className="general-info-camp flex flex-col">
                <div className="wrap-info flex flex-col">
                  <div className="page-title mb-5">Immigration Details</div>
                  <div className="row">
                    <div className="field flex flex-col">
                      <div className="lbl">Immigration Status</div>
                      <Select
                        defaultValue={selectedImmigration}
                        value={selectedImmigration}
                        onChange={setSelectedImmigration}
                        getOptionLabel={(option) => option.display}
                        getOptionValue={(option) => option.value}
                        options={optionsList?.data?.imigrationStatusList}
                        className="select"
                        id="imigrationStatus"
                        name="imigrationStatus"
                        placeholder="Immigration Status"
                        isDisabled={inputReadOnly}
                      />
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl">Identification Number</div>
                      <input
                        name="identificationNumber"
                        type="text"
                        className="txt cleanbtn"
                        placeholder="Identification Number"
                        value={userData.identificationNumber}
                        onChange={handleInput}
                        readOnly={inputReadOnly}
                      />
                    </div>
                    {selectedImmigration?.display === "S Pass" ||
                    selectedImmigration?.display === "sPass" ||
                    selectedImmigration?.display === "PRC WP" ||
                    selectedImmigration?.display === "prcwp" ||
                    selectedImmigration?.display === "workPermit" ||
                    selectedImmigration?.display === "Work Permit" ? (
                      <>
                        <div className="field flex flex-col">
                          <div className="lbl">Issue Date</div>
                          <div className="date-picker flex aic jc">
                            {inputReadOnly === false ? (
                              <Datetime
                                closeOnSelect={true}
                                value={
                                  issueDate ? issueDate : new Date().getTime()
                                }
                                onChange={(value) => {
                                  {
                                    setIssueDate(new Date(value).getTime());
                                    setUserData({
                                      ...userData,
                                      issueDate: new Date(value).getTime(),
                                    });
                                  }
                                }}
                                timeFormat={false}
                                dateFormat="MMMM Do, YYYY"
                                className="start-date cleanbtn"
                              />
                            ) : (
                              <Datetime
                                value={
                                  issueDate ? issueDate : new Date().getTime()
                                }
                                timeFormat={false}
                                dateFormat="MMMM Do, YYYY"
                                className="start-date cleanbtn"
                              />
                            )}

                            <div className="ico">
                              <CalendarIcon />
                            </div>
                          </div>
                        </div>
                        <div className="field flex flex-col">
                          <div className="lbl">Expiry Date</div>
                          <div className="date-picker flex aic jc">
                            {inputReadOnly === false ? (
                              <Datetime
                                closeOnSelect={true}
                                value={expDate ? expDate : new Date().getTime()}
                                onChange={(value) => {
                                  setExpDate(new Date(value).getTime());
                                  setUserData({
                                    ...userData,
                                    expiryDate: new Date(value).getTime(),
                                  });
                                }}
                                timeFormat={false}
                                dateFormat="MMMM Do, YYYY"
                                className="start-date cleanbtn"
                              />
                            ) : (
                              <Datetime
                                value={expDate ? expDate : new Date().getTime()}
                                timeFormat={false}
                                dateFormat="MMMM Do, YYYY"
                                className="start-date cleanbtn"
                              />
                            )}
                            <div className="ico">
                              <CalendarIcon />
                            </div>
                          </div>
                        </div>
                        <div className="field flex flex-col">
                          <div className="lbl">Tier</div>
                          <Select
                            defaultValue={selectedTier}
                            value={selectedTier}
                            onChange={(option) => setSelectedTier(option)}
                            getOptionLabel={(option) => option.display}
                            getOptionValue={(option) => option.value}
                            options={optionsList?.data?.tierList}
                            className="select"
                            id="Tier"
                            name="Tier"
                            placeholder="Tier"
                            isDisabled={inputReadOnly}
                          />
                        </div>
                        <div className="field flex flex-col">
                          <div className="lbl">Tier Category</div>
                          <Select
                            defaultValue={selectedTierCategory}
                            value={selectedTierCategory}
                            onChange={(option) =>
                              setSelectedTierCategory(option)
                            }
                            getOptionLabel={(option) => option.display}
                            getOptionValue={(option) => option.value}
                            options={optionsList?.data?.skillCategoryList}
                            className="select"
                            id="TierCategory"
                            name="TierCategory"
                            placeholder="Tier Category"
                            isDisabled={inputReadOnly}
                          />
                        </div>
                      </>
                    ) : null}
                    <div className="field flex flex-col">
                      <div className="lbl">Nationality</div>
                      <Select
                        defaultValue={selectedNationality}
                        value={selectedNationality}
                        onChange={setSelectedNationality}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.country_code}
                        options={countries?.data}
                        className="select"
                        id="nationality"
                        name="nationality"
                        placeholder="Nationality"
                        isDisabled={inputReadOnly}
                      />
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl">Race</div>
                      <Select
                        defaultValue={selectedRace}
                        value={selectedRace}
                        onChange={setSelectedRace}
                        getOptionLabel={(option) => option.display}
                        getOptionValue={(option) => option.value}
                        options={optionsList?.data?.raceList}
                        className="select"
                        id="race"
                        name="race"
                        placeholder="Race"
                        isDisabled={inputReadOnly}
                      />
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl">Religion</div>
                      <Select
                        defaultValue={selectedReligion}
                        value={selectedReligion}
                        onChange={setSelectedReligion}
                        getOptionLabel={(option) => option.display}
                        getOptionValue={(option) => option.value}
                        options={optionsList?.data?.religionList}
                        className="select"
                        id="religion"
                        name="religion"
                        placeholder="Religion"
                        isDisabled={inputReadOnly}
                      />
                    </div>
                  </div>
                  <div className="page-title mb-5  mt-5">Statutory Details</div>
                  <div className="row">
                    <div className="field flex flex-col">
                      <div className="lbl">Group Contribution</div>
                      <Select
                        defaultValue={selectedGroupContribution}
                        value={selectedGroupContribution}
                        onChange={setSelectedGroupContribution}
                        getOptionLabel={(option) => option.display}
                        getOptionValue={(option) => option.value}
                        options={optionsList?.data?.shgsList}
                        className="select"
                        id="groupContribution"
                        name="groupContribution"
                        placeholder="Group Contribution"
                        isDisabled={inputReadOnly}
                      />
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl">CPF Account Number</div>
                      <input
                        name="cpfAccountNo"
                        type="text"
                        className="txt cleanbtn"
                        placeholder="CPF Account Number"
                        value={userData.cpfAccountNo}
                        onChange={handleInput}
                        disabled={inputReadOnly}
                      />
                    </div>
                    <div className="field flex flex-col">
                      <div className="item flex aic mb-2">
                        <input
                          type="checkbox"
                          name="SDL"
                          value="SDL"
                          checked={userData.applicable.SDL}
                          onChange={handleApplicable}
                          className="check-box cursor-pointer mr-4 h-4 w-4"
                          disabled={inputReadOnly}
                        />
                        <div className="check-box-lbl s14">SDL Applicable </div>
                      </div>
                      <div className="item flex aic mb-2">
                        <input
                          type="checkbox"
                          name="FWL"
                          value="FWL"
                          checked={userData.applicable.FWL}
                          onChange={handleApplicable}
                          className="check-box cursor-pointer mr-4 h-4 w-4"
                          disabled={inputReadOnly}
                        />
                        <div className="check-box-lbl s14">FWL Applicable </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : step === 6 ? (
              <div className="general-info-camp flex flex-col">
                <div className="wrap-info flex flex-col">
                  <div className="page-title mb-5">Bank Information</div>
                  <div className="add-bank flex aic mb-5">
                    {inputReadOnly === false && (
                      <>
                        <div className="lbl mr-2">Add Bank Information</div>
                        <div
                          className="icon cursor-pointer"
                          onClick={(e) => {
                            setAddBank(true);
                          }}
                        >
                          <AddIcon />
                        </div>
                      </>
                    )}
                  </div>
                  {userInfo?.bank?.accountNo !== "" || addBank ? (
                    <div className="row">
                      <div className="field flex flex-col">
                        <div className="lbl">Bank</div>
                        <Select
                          defaultValue={selectedBank}
                          onChange={setSelectedBank}
                          getOptionLabel={(option) => option.display}
                          getOptionValue={(option) => option.value}
                          options={optionsList?.data.bankList}
                          className="select"
                          id="bankOf"
                          name="bankOf"
                          placeholder="Select Bank"
                          isDisabled={inputReadOnly}
                        />
                      </div>
                      <div className="field flex flex-col">
                        <div className="lbl">Account Name</div>
                        <input
                          name="accountName"
                          type="text"
                          className="txt cleanbtn"
                          placeholder="Account Name"
                          value={userData?.bank?.accountName}
                          onChange={handleBankInput}
                          disabled={inputReadOnly}
                        />
                      </div>
                      <div className="field flex flex-col">
                        <div className="lbl">Account No.</div>
                        <input
                          name="accountNo"
                          type="text"
                          className="txt cleanbtn"
                          placeholder="Account No."
                          value={userData?.bank?.accountNo}
                          onChange={handleBankInput}
                          disabled={inputReadOnly}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center justify-center h-full text-2xl  font-medium uppercase">
                      Add your bank information
                    </div>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <Loader />
        )}
        <div className="action flex aic justify-end">
          {step > 1 && (
            <div className="btn button mr-[12px]" onClick={(e) => prevStep()}>
              Previous
            </div>
          )}

          {step === 6 ? (
            <div className="btn button" onClick={(e) => updateUserData(e)}>
              Update
            </div>
          ) : (
            <div
              className={`btn button  ${step === 6 ? "hidden" : ""}`}
              // onClick={(e) => nextStep()}
              onClick={handleNextButton}
            >
              Next
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdateUser;
