import React, { useState, useEffect, useInsertionEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Select from "react-select";
import Datetime from "react-datetime";
import Loader from "../../components/Loader";
import TagsInput from "../../components/TagsInput";

import { CalendarIcon, AddIcon, EditIcon, DelIcon } from "../../assets";

import { useFetchUserInfoQuery } from "../../store/services/generalService";
import {
  useUpdateUserMutation,
  useGetUsersListQuery,
  useFetchSingleUserQuery,
} from "../../store/services/usersService";
import {
  useGetQuery,
  useGetCountryQuery,
  useGetStateQuery,
  useGetCityQuery,
} from "../../store/services/generalService";

import { useGetDepartListQuery } from "../../store/services/departmentsService";

const AccountInfo = () => {
  const navigate = useNavigate();
  const [uId, setUid] = useState("");

  const { userRole, userToken, userOrgId, userId } = useSelector(
    (state) => state.authReducer
  );
  const { data: userInfo, isFetching } = useFetchUserInfoQuery(userId);

  const { data: usersList, isFetching: isFetchingUsersList } =
    useGetUsersListQuery();

  const { data: singleUserData, isFetching: singleUserDataFetching } =
    useFetchSingleUserQuery(userId);

  const [step, setStep] = useState(1);
  const nextStep = () => {
    setStep(step + 1);
    document.documentElement.scrollTop = 0;
  };
  const prevStep = () => {
    setStep(step - 1);
    document.documentElement.scrollTop = 0;
  };

  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    contactNo: "",
    nationalId: "",
    dateOfBirth: "",
    department_id: "",
    designation: "",
    reportTo: "",
    status: "",
    country: "",
    state: "",
    city: "",
    postalCode: "",
    address: [],
  });

  const [dateBirth, setDateBirth] = useState();
  const [reportTo, setReportTo] = useState();

  const [selectedCountry, setSelectedCountry] = useState({
    name: "",
    country_code: "",
  });
  const [selectedState, setSelectedState] = useState({
    name: "",
    country_code: "",
  });
  const [selectedCity, setSelectedCity] = useState({
    name: "",
    country_code: "",
  });
  const [selectedDepart, setSelectedDepart] = useState({
    name: "",
    _id: "",
  });
  const [selectedStatus, setSelectedStatus] = useState({
    display: "",
    value: "",
  });

  const {
    data: optionsList,
    isLoading: optionsListLoading,
    isFetching: optionsListFetching,
  } = useGetQuery();

  const {
    data: countries,
    isLoading: countriesLoading,
    isFetching: countriesFetching,
  } = useGetCountryQuery();

  const {
    data: states,
    isLoading: statesLoading,
    isFetching: statesFetching,
  } = useGetStateQuery(selectedCountry?.country_code);

  const {
    data: cites,
    isLoading: citesLoading,
    isFetching: citesFetching,
  } = useGetCityQuery({
    countryCode: selectedCountry?.country_code,
    stateCode: selectedState?.state_code,
  });

  const {
    data: departmentsList,
    isLoading: departmentLoading,
    isFetching: departmentFetching,
  } = useGetDepartListQuery();

  const handleInput = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  //Handle Address
  const handleAddress = (index) => (e) => {
    let newArr = [...userData?.address];
    newArr[index] = e.target.value;
    setUserData({ ...userData, address: newArr });
  };

  // Handle remove Address
  let newAddressArr = [...userData?.address];
  const removeAddress = (index) => {
    newAddressArr = newAddressArr.filter((el, i) => i !== index);
    setUserData({ ...userData, address: newAddressArr });
  };

  useEffect(() => {
    setUserData({ ...userData, country: selectedCountry?.name });
  }, [selectedCountry]);

  useEffect(() => {
    setUserData({ ...userData, state: selectedState?.name });
  }, [selectedState]);
  useEffect(() => {
    setUserData({ ...userData, city: selectedCity?.name });
  }, [selectedCity]);
  useEffect(() => {
    setUserData({ ...userData, department_id: selectedDepart?._id });
  }, [selectedDepart]);

  useEffect(() => {
    setUserData({ ...userData, status: selectedStatus?.value });
  }, [selectedStatus]);

  useEffect(() => {
    let result = reportTo?.length > 0 ? reportTo.map((a) => a._id) : [];
    setUserData({ ...userData, reportTo: result });
  }, [reportTo]);

  const [updateUser, response] = useUpdateUserMutation();

  const errors = response?.error?.data ? response?.error?.data.message : null;

  const updateUserData = (e) => {
    e.preventDefault();
    updateUser(userData);
  };

  useEffect(() => {
    if (response?.isSuccess) {
      navigate("/");
    }
  }, [response?.isSuccess]);

  useEffect(() => {
    if (!singleUserDataFetching) {
      setUserData(singleUserData?.data);
      setSelectedDepart(singleUserData?.data?.department_id);
      setDateBirth(new Date(singleUserData?.data?.dateOfBirth)?.getTime());
      setSelectedStatus({
        display: singleUserData?.data?.status,
        value: singleUserData?.data?.status,
      });
      setSelectedCountry({
        name: singleUserData?.data?.country,
        country_code: singleUserData?.data?.country,
      });
      setSelectedState({
        name: singleUserData?.data?.state,
        country_code: singleUserData?.data?.state,
      });
      setSelectedCity({
        name: singleUserData?.data?.city,
        country_code: singleUserData?.data?.city,
      });
      setReportTo(singleUserData?.data?.reportTo);
    }
  }, [singleUserDataFetching]);

  return (
    <div className="create-new-user-page flex">
      <div className="wrap wrapWidth flex flex-col">
        <div className="page-hdr flex">
          <div className="steps-blk flex aic justify-between rel">
            <div className="line abs"></div>
            <div
              className={`step-btn flex aic jc cursor-pointer ${
                step === 1 || step === 2 ? "active" : ""
              }`}
            >
              General Information
            </div>
            <div
              className={`step-btn flex aic jc cursor-pointer ${
                step === 2 ? "active" : ""
              }`}
            >
              Contact Information
            </div>
          </div>
        </div>
        {errors !== null && (
          <div className="error-message mt-4 flex bg-red-100 text-red-700 p-3  mb-2 rounded-md text-sm font-medium">
            {errors}
          </div>
        )}
        {!singleUserDataFetching ? (
          <div className="content">
            {step === 1 ? (
              <div className="general-info-camp flex flex-col">
                <div className="wrap-info flex flex-col">
                  <div className="page-title mb-5">General Information</div>
                  <div className="row">
                    <div className="field flex flex-col">
                      <div className="lbl">First name</div>
                      <input
                        name="firstName"
                        type="text"
                        className="txt cleanbtn"
                        placeholder="First name"
                        value={userData.firstName}
                        onChange={handleInput}
                      />
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl">Last name</div>
                      <input
                        name="lastName"
                        type="text"
                        className="txt cleanbtn"
                        placeholder="Last name"
                        value={userData.lastName}
                        onChange={handleInput}
                      />
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl">Username</div>
                      <input
                        name="userName"
                        type="text"
                        className="txt cleanbtn"
                        placeholder="Username"
                        value={userData.userName}
                        onChange={handleInput}
                      />
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl">Email</div>
                      <input
                        name="email"
                        type="text"
                        className="txt cleanbtn"
                        placeholder="Email"
                        value={userData.email}
                        onChange={handleInput}
                      />
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl">Contact no</div>
                      <input
                        name="contactNo"
                        type="text"
                        className="txt cleanbtn"
                        placeholder="Contact no"
                        value={userData.contactNo}
                        onChange={handleInput}
                      />
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl">National id</div>
                      <input
                        name="nationalId"
                        type="text"
                        className="txt cleanbtn"
                        placeholder="National id"
                        value={userData.nationalId}
                        onChange={handleInput}
                      />
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl">Date of birth</div>
                      <div className="date-picker flex aic jc">
                        <Datetime
                          closeOnSelect={true}
                          value={dateBirth ? dateBirth : new Date().getTime()}
                          onChange={(value) => {
                            {
                              setDateBirth(new Date(value).getTime());
                              setUserData({
                                ...userData,
                                dateOfBirth: new Date(value).getTime(),
                              });
                            }
                          }}
                          timeFormat={false}
                          dateFormat="MMMM Do, YYYY"
                          className="start-date cleanbtn"
                        />
                        <div className="ico">
                          <CalendarIcon />
                        </div>
                      </div>
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl">Department</div>
                      <Select
                        defaultValue={selectedDepart}
                        value={selectedDepart}
                        onChange={setSelectedDepart}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option._id}
                        options={departmentsList?.data}
                        name="department_id"
                        className="select"
                        placeholder="Department"
                      />
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl">Designation</div>
                      <input
                        name="designation"
                        type="text"
                        className="txt cleanbtn"
                        placeholder="Designation"
                        value={userData.designation}
                        onChange={handleInput}
                      />
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl">Report to</div>
                      <Select
                        defaultValue={reportTo}
                        value={reportTo}
                        onChange={setReportTo}
                        isMulti
                        getOptionLabel={(option) => option.firstName}
                        getOptionValue={(option) => option.firstName}
                        options={usersList?.data}
                        name="reportTo"
                        className="select"
                        placeholder="Report To"
                      />
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl">Status</div>
                      <Select
                        defaultValue={selectedStatus}
                        value={selectedStatus}
                        onChange={setSelectedStatus}
                        getOptionLabel={(option) => option.display}
                        getOptionValue={(option) => option.value}
                        options={optionsList?.data?.userStatuses}
                        name="status"
                        className="select"
                        placeholder="Status"
                      />
                    </div>
                    {/* <div className="field flex flex-col">
                      <div className="lbl">Sector</div>
                      <Select
                        defaultValue={selectedSector}
                        value={selectedSector}
                        onChange={setSelectedSector}
                        getOptionLabel={(option) => option.display}
                        getOptionValue={(option) => option.value}
                        options={optionsList?.data?.sectorList}
                        name="sector"
                        className="select"
                        placeholder="Sector"
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            ) : step === 2 ? (
              <div className="general-info-camp flex flex-col">
                <div className="wrap-info flex flex-col">
                  <div className="page-title mb-5">Contact Information</div>
                  <div className="row">
                    <div className="field flex flex-col">
                      <div className="lbl">Country</div>
                      <Select
                        defaultValue={selectedCountry}
                        value={selectedCountry}
                        onChange={setSelectedCountry}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.country_code}
                        options={countries?.data}
                        className="select"
                        id="country"
                        name="country"
                        placeholder="Select Country"
                      />
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl">State/Region</div>
                      <Select
                        defaultValue={selectedState}
                        value={selectedState}
                        onChange={setSelectedState}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.state_code}
                        options={states?.data}
                        className="select"
                        placeholder="State/Region"
                      />
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl">City</div>
                      <Select
                        defaultValue={selectedCity}
                        value={selectedCity}
                        onChange={setSelectedCity}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.state_code}
                        options={cites?.data}
                        className="select"
                        placeholder="City"
                      />
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl">Postal Code</div>
                      <input
                        name="postalCode"
                        type="text"
                        className="txt cleanbtn"
                        placeholder="Postal Code"
                        value={userData.postalCode}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="flex items-center py-5">
                      <div className="lbl mr-5">Add Address</div>
                      <div
                        className="ico hover:cursor-pointer"
                        onClick={(e) => {
                          setUserData({
                            ...userData,
                            address: [...userData.address, ""],
                          });
                        }}
                      >
                        <AddIcon />
                      </div>
                    </div>
                    <div className="row">
                      {userData?.address?.length > 0 ? (
                        <>
                          {userData?.address.map((item, index) => (
                            <div key={index} className="field flex flex-col">
                              <div className="lbl">Address {index + 1}</div>{" "}
                              <div className="bd rounded-md flex items-center">
                                <input
                                  type="text"
                                  name="address"
                                  className="w-full p-2 mx-1"
                                  value={item}
                                  placeholder="Address"
                                  onChange={handleAddress(index)}
                                />
                                <div
                                  className="cross-icon cursor-pointer"
                                  onClick={(e) => removeAddress(index)}
                                >
                                  X
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <Loader />
        )}
        <div className="action flex aic justify-end">
          {step > 1 && (
            <div className="btn button mr-[12px]" onClick={(e) => prevStep()}>
              Previous
            </div>
          )}
          <div
            className={`btn button  ${step === 2 ? "hidden" : ""}`}
            onClick={(e) => nextStep()}
          >
            Next
          </div>
          {step === 2 && (
            <div className="btn button" onClick={(e) => updateUserData(e)}>
              Update
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccountInfo;
