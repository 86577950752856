import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import { DropDownIcon } from "../../assets";
import { useCreateComponentMutation } from "../../store/services/componentService";

const CreateNewComponent = () => {
  const navigate = useNavigate();

  const [hide, setHide] = useState(false);
  const [selectedCompany, setselectedcompany] = useState();
  const [statusData, setStatusData] = useState([
    { id: "deduction", title: "Deduction" },
    { id: "earning", title: "Earning" },
  ]);
  useEffect(() => {
    document.addEventListener("click", () => {
      setHide(false);
    });
  }, []);

  const [createComponent, setCreateComponent] = useState({
    name: "",
    type: "",
    ccType: "",
    amount: "",
    status: "active",
    applicable: { CPF: "false", SDL: "false" },
  });
  const handleInput = (e) => {
    setCreateComponent({ ...createComponent, [e.target.name]: e.target.value });
  };

  const [cpf, setCpf] = useState(false);
  const [sdl, setSdl] = useState(false);
  const [selection, setSelection] = useState(false);
  const [selection1, setSelection1] = useState(false);

  const [saveComponent, data] = useCreateComponentMutation();
  const errors = data?.error?.data ? data?.error?.data.message : null;

  const createNewComponent = (e) => {
    e.preventDefault();
    saveComponent(createComponent);
  };

  useEffect(() => {
    if (data?.isSuccess) {
      navigate("/component");
    }
  }, [data?.isSuccess]);

  return (
    <div className="new-department flex">
      <div className="wrap wrapWidth flex flex-col">
        {errors !== null && (
          <div className="flex bg-red-100 text-red-700 p-3  mb-2 rounded-md text-sm font-medium">
            {errors}
          </div>
        )}
        <div className="row">
          <div className="field flex flex-col">
            <div className="lbl">Name</div>
            <input
              // id="name"
              name="name"
              // value={setCreateComponent.name}
              onChange={handleInput}
              type="text"
              className="txt"
              placeholder="Name"
            />
          </div>
          <div className="field flex flex-col">
            <div className="lbl">Type</div>
            <div className="dropDown flex aic flex-col rel ">
              <div className="category flex aic">
                <div
                  className="cbox cleanbtn flex aic rel pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    setHide(!hide);
                  }}
                >
                  <div className="slt flex aic">
                    <div className="unit-name flex aic font s14 b4">
                      <span
                        className="unit-eng capitalize flex aic font s14 b4"
                        placeholder="Type"
                      >
                        {selectedCompany ? selectedCompany : "Deduction"}
                      </span>
                    </div>
                  </div>

                  <div>
                    <DropDownIcon />
                  </div>
                </div>
              </div>
              <div className={`block flex aic abs ${hide ? "show" : ""}`}>
                <div className="manue flex aic col anim">
                  {statusData.map((item, index) => (
                    <div
                      key={index}
                      className="slt flex aic"
                      onClick={(e) => {
                        setHide(!hide);
                        setselectedcompany(item.title);
                        setCreateComponent({
                          ...createComponent,
                          ccType: item.id,
                        });
                      }}
                    >
                      <div className="unit-name flex aic font s14 b4">
                        <span className="unit-eng flex aic font s14 b4">
                          {item.title}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="field flex flex-col">
            <div className="lbl">Amount</div>
            <input
              id="amount"
              name="amount"
              value={setCreateComponent.amount}
              onChange={handleInput}
              type="text"
              className="txt"
              placeholder="Amount"
            />
          </div>
          <div className="field flex items-start justify-start">
            <div className="radito-select flex items-center mt-6">
              <div className="c555 font lbl">Percentage</div>
              <button
                onClick={(e) => {
                  setSelection(true);
                  setSelection1(false);
                  setCreateComponent({
                    ...createComponent,
                    type: "percentage",
                  });
                }}
                className={`cleanbtn radio-btn rel ${selection ? "on" : ""}`}
              />
            </div>
            <div className="radito-select flex items-center mt-6">
              <div className="c555 b4 font lbl">Fixed</div>
              <button
                onClick={(e) => {
                  setSelection1(true);
                  setSelection(false);
                  setCreateComponent({
                    ...createComponent,
                    type: "fixed",
                  });
                }}
                className={`cleanbtn radio-btn rel ${selection1 ? "on" : ""}`}
              />
            </div>
          </div>
          <div className="field flex flex-col">
            <div className="lbl">Applied to</div>
            <div className="select-box flex aic">
              <div className="box-tag">CPF</div>
              <button
                onClick={(e) => {
                  setCpf(!cpf);
                  if (createComponent.applicable.CPF === "false") {
                    setCreateComponent((prevState) => ({
                      ...prevState,
                      applicable: {
                        ...prevState.applicable,
                        CPF: "true",
                      },
                    }));
                  } else {
                    setCreateComponent((prevState) => ({
                      ...prevState,
                      applicable: {
                        ...prevState.applicable,
                        CPF: "false",
                      },
                    }));
                  }
                }}
                className={`cleanbtn checkbox-btn rel ${cpf ? "on" : ""}`}
              />
            </div>
            <div className="select-box flex aic">
              <div className="box-tag">SDL</div>
              <button
                onClick={(e) => {
                  setSdl(!sdl);
                  if (createComponent.applicable.SDL === "false") {
                    setCreateComponent((prevState) => ({
                      ...prevState,
                      applicable: {
                        ...prevState.applicable,
                        SDL: "true",
                      },
                    }));
                  } else {
                    setCreateComponent((prevState) => ({
                      ...prevState,
                      applicable: {
                        ...prevState.applicable,
                        SDL: "false",
                      },
                    }));
                  }
                }}
                className={`cleanbtn checkbox-btn rel ${sdl ? "on" : ""}`}
              />
            </div>
          </div>
        </div>
        <div className="action flex aic justify-end">
          <div className="btn button" onClick={(e) => createNewComponent(e)}>
            {data.isLoading ? "Loading..." : "Create"}
          </div>
          {/* <input
            type="submit"
            value={data.isLoading ? "Loading..." : "Create"}
            className="btn button"
          /> */}
        </div>
      </div>
    </div>
  );
};

export default CreateNewComponent;
