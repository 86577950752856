import { configureStore } from "@reduxjs/toolkit";

import authService from "./services/authService";
import corporateService from "./services/corporateService";
import departmentsService from "./services/departmentsService";
import bankAccountService from "./services/bankAccountService";
import generalService from "./services/generalService";
import cpfAccountService from "./services/cpfAccountService";
import componentService from "./services/componentService";
import usersService from "./services/usersService";
import payrollService from "./services/payrollService";
import claimsService from "./services/claimsService";
import dashboardService from "./services/dashboardService";
import reportService from "./services/reportService";
import activityService from "./services/activityService";

import authReducer from "./reducers/authReducer";
import globalReducer from "./reducers/globalReducer";

const store = configureStore({
  reducer: {
    [authService.reducerPath]: authService.reducer,
    [corporateService.reducerPath]: corporateService.reducer,
    [departmentsService.reducerPath]: departmentsService.reducer,
    [bankAccountService.reducerPath]: bankAccountService.reducer,
    [generalService.reducerPath]: generalService.reducer,
    [cpfAccountService.reducerPath]: cpfAccountService.reducer,
    [componentService.reducerPath]: componentService.reducer,
    [usersService.reducerPath]: usersService.reducer,
    [payrollService.reducerPath]: payrollService.reducer,
    [claimsService.reducerPath]: claimsService.reducer,
    [dashboardService.reducerPath]: dashboardService.reducer,
    [reportService.reducerPath]: reportService.reducer,
    [activityService.reducerPath]: activityService.reducer,

    authReducer: authReducer,
    globalReducer: globalReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      corporateService.middleware,
      departmentsService.middleware,
      bankAccountService.middleware,
      generalService.middleware,
      cpfAccountService.middleware,
      componentService.middleware,
      usersService.middleware,
      payrollService.middleware,
      claimsService.middleware,
      dashboardService.middleware,
      reportService.middleware,
      activityService.middleware,
    ]),
});

export default store;
