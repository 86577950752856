import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Datetime from "react-datetime";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useDispatch } from "react-redux";

import { CalendarIcon, AddIcon } from "../../assets";
import Loader from "../../components/Loader";
import TagsInput from "../../components/TagsInput";

import {
  useGetQuery,
  useGetCountryQuery,
  useGetStateQuery,
  useGetCityQuery,
} from "../../store/services/generalService";

import { useGetGeneralInfoQuery } from "../../store/services/usersService";

import { useUpdateInfoMutation } from "../../store/services/corporateService";

const UpdateCompanyInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedCountry, setSelectedCountry] = useState({
    name: "",
    country_code: "",
  });
  const [selectedState, setSelectedState] = useState({
    name: "",
    country_code: "",
  });

  const [selectedCity, setSelectedCity] = useState({
    name: "",
    country_code: "",
  });

  const [selectedSector, setSelectedSector] = useState({
    display: "",
    value: "",
  });

  const {
    data: optionsList,
    isLoading: optionsListLoading,
    isFetching: optionsListFetching,
  } = useGetQuery();

  const { data: countries, isLoading, isFetching } = useGetCountryQuery();

  const {
    data: infoData,
    isLoading: infoDataLoading,
    isFetching: infoDataFetching,
  } = useGetGeneralInfoQuery();

  const {
    data: states,
    isLoading: statesLoading,
    isFetching: statesFetching,
  } = useGetStateQuery(selectedCountry?.country_code);

  const {
    data: cites,
    isLoading: citesLoading,
    isFetching: citesFetching,
  } = useGetCityQuery({
    countryCode: selectedCountry?.country_code,
    stateCode: selectedState?.state_code,
  });

  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  useEffect(() => {
    if (!isLoading) {
      setCountryList(countries?.data);
    }
  }, [countries?.data]);

  useEffect(() => {
    if (!statesLoading) {
      setStateList(states?.data);
    }
  }, [states?.data]);

  useEffect(() => {
    if (!citesLoading) {
      setCityList(cites?.data);
    }
  }, [citesFetching]);

  const [updateInfo, setUpdateInfo] = useState({
    corpName: "",
    alias: [],
    uen: "",
    dateOfIncorporation: "",
    CPFCSNNo: "",
    minSalary: 0,
    contactNo: "",
    country: "",
    state: "",
    city: "",
    postalCode: "",
    sector: "",
    address: [],
  });

  // new Date(Number(itemtobemodified.manufacturingdate)).getTime()
  // new Date(updateInfo?.dateOfIncorporation)?.getTime()
  const [date, setDate] = useState(
    new Date(Number(updateInfo?.dateOfIncorporation)).getTime()
  );

  const handleInput = (e) => {
    setUpdateInfo({ ...updateInfo, [e.target.name]: e.target.value });
  };
  const handleAddress = (index) => (e) => {
    let newArr = [...updateInfo?.address];
    newArr[index] = e.target.value;
    setUpdateInfo({ ...updateInfo, address: newArr });
  };

  const [selectedAlias, setSelectedAlias] = useState();

  function handleKeyDown(e) {
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim()) return;
    setSelectedAlias([...selectedAlias, value]);
    e.target.value = "";
  }

  function removeTag(index) {
    setSelectedAlias(selectedAlias.filter((el, i) => i !== index));
  }

  useEffect(() => {
    setUpdateInfo({ ...updateInfo, alias: selectedAlias });
  }, [selectedAlias]);

  useEffect(() => {
    if (infoData?.data) {
      setUpdateInfo(infoData?.data);
      setSelectedAlias(infoData.data.alias);
      // setDate(new Date(updateInfo?.dateOfIncorporation)?.getTime());
      setSelectedCountry({
        name: infoData?.data.country,
        country_code: infoData?.data.country,
      });
      setSelectedState({
        name: infoData?.data.state,
        country_code: infoData?.data.state,
      });
      setSelectedCity({
        name: infoData?.data.city,
        country_code: infoData?.data.city,
      });
      setSelectedSector({
        display: infoData?.data?.sector,
        value: infoData?.data?.sector,
      });
    }
  }, [infoDataFetching]);

  useEffect(() => {
    setUpdateInfo(infoData?.data);
    setSelectedAlias(infoData?.data?.alias);
    // setDate(new Date(updateInfo?.dateOfIncorporation)?.getTime());
    setSelectedCountry({
      name: infoData?.data?.country,
      country_code: infoData?.data?.country,
    });
    setSelectedState({
      name: infoData?.data?.state,
      country_code: infoData?.data?.state,
    });
    setSelectedCity({
      name: infoData?.data?.city,
      country_code: infoData?.data?.city,
    });
    setSelectedSector({
      display: infoData?.data?.sector,
      value: infoData?.data?.sector,
    });
  }, [infoData?.data]);

  useEffect(() => {
    setUpdateInfo({ ...updateInfo, country: selectedCountry?.name });
  }, [selectedCountry]);
  useEffect(() => {
    setUpdateInfo({ ...updateInfo, state: selectedState?.name });
  }, [selectedState]);
  useEffect(() => {
    setUpdateInfo({ ...updateInfo, city: selectedCity?.name });
  }, [selectedCity]);

  useEffect(() => {
    setUpdateInfo({ ...updateInfo, sector: selectedSector?.value });
  }, [selectedSector]);

  const [updateCompanyInfo, response] = useUpdateInfoMutation();
  const errors = response?.error?.data ? response?.error?.data.message : null;

  const updateGeneralInfo = (e) => {
    e.preventDefault();
    updateCompanyInfo(updateInfo);
  };

  useEffect(() => {
    if (response?.isSuccess) {
      navigate("/general");
    }
  }, [response?.isSuccess]);

  return (
    <div className="company-info flex">
      <div className="wrap wrapWidth flex flex-col">
        {!isLoading &&
        !isFetching &&
        !infoDataLoading &&
        !infoDataFetching &&
        !optionsListLoading &&
        !optionsListFetching ? (
          <>
            {errors !== null && (
              <div className="flex bg-red-100 text-red-700 p-3  mb-2 rounded-md text-sm font-medium">
                {errors}
              </div>
            )}
            <div className="wrap-block">
              <div className="field flex flex-col">
                <div className="lbl">Corporate name</div>
                <input
                  type="text"
                  id="corpName"
                  name="corpName"
                  value={updateInfo.corpName}
                  onChange={handleInput}
                  className="txt"
                  placeholder="Corporate name"
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Alias</div>
                {/* <TagsInput
                  value={updateInfo.alias}
                  onChange={setSelectedAlias}
                  name="alias"
                  placeHolder="Enter Alias"
                /> */}
                <div className="tags-input-container">
                  {selectedAlias?.map((tag, index) => (
                    <div className="tag-item" key={index}>
                      <span className="text">{tag}</span>
                      <span className="close" onClick={() => removeTag(index)}>
                        &times;
                      </span>
                    </div>
                  ))}

                  <input
                    onKeyDown={handleKeyDown}
                    type="text"
                    className="tags-input"
                    placeholder={`${
                      selectedAlias?.length == 0
                        ? "Alias is null"
                        : "Type something"
                    }`}
                  />
                </div>
              </div>
              <div className="field flex flex-col">
                <div className="lbl">UEN</div>
                <input
                  type="text"
                  id="uen"
                  name="uen"
                  className="txt"
                  value={updateInfo.uen}
                  onChange={handleInput}
                  placeholder="UEN"
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Date of Incorporation</div>
                <div className="date-picker flex aic justify-between">
                  <Datetime
                    closeOnSelect={true}
                    value={date ? date : new Date().getTime()}
                    onChange={(value) => {
                      setDate(new Date(value).getTime());
                      setUpdateInfo({
                        ...updateInfo,
                        dateOfIncorporation: new Date(value).getTime(),
                      });
                    }}
                    timeFormat={false}
                    dateFormat="DD-MM-YYYY"
                    className="start-date cleanbtn"
                  />
                  <div className="ico">
                    <CalendarIcon />
                  </div>
                </div>
              </div>
              <div className="field flex flex-col">
                <div className="lbl">CPF CSN Number</div>
                <input
                  type="text"
                  id="CPFCSNNo"
                  name="CPFCSNNo"
                  className="txt"
                  value={updateInfo.CPFCSNNo}
                  onChange={handleInput}
                  placeholder="CPF CSN Number"
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">CPF Employee Min Salary</div>
                <input
                  type="number"
                  id="minSalary"
                  name="minSalary"
                  className="txt"
                  value={updateInfo.minSalary}
                  onChange={handleInput}
                  placeholder="CPF Employee Min Salary"
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Contact no</div>
                <input
                  type="text"
                  id="contactNo"
                  name="contactNo"
                  className="txt"
                  value={updateInfo.contactNo}
                  onChange={handleInput}
                  placeholder="Contact no"
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Country</div>
                <Select
                  defaultValue={selectedCountry}
                  value={selectedCountry}
                  onChange={setSelectedCountry}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.country_code}
                  options={countryList}
                  className="select"
                  id="country"
                  name="country"
                  placeholder="Select Country"
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">State/Region</div>
                <Select
                  defaultValue={selectedState}
                  value={selectedState}
                  onChange={setSelectedState}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.state_code}
                  options={stateList}
                  className="select"
                  id="state"
                  name="state"
                  placeholder="Select State"
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">City</div>
                <Select
                  defaultValue={selectedCity}
                  value={selectedCity}
                  onChange={setSelectedCity}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.state_code}
                  options={cityList}
                  className="select"
                  id="city"
                  name="city"
                  placeholder="Select City"
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Postal Code</div>
                <input
                  type="text"
                  name="postalCode"
                  className="txt"
                  value={updateInfo.postalCode}
                  onChange={handleInput}
                  placeholder="Postal Code"
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Sector</div>
                <Select
                  defaultValue={selectedSector}
                  value={selectedSector}
                  onChange={setSelectedSector}
                  getOptionLabel={(option) => option.display}
                  getOptionValue={(option) => option.value}
                  options={optionsList?.data?.sectorList}
                  name="sector"
                  className="select"
                  placeholder="Sector"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex items-center py-5">
                <div className="lbl mr-5">Add Address</div>
                <div
                  className="ico hover:cursor-pointer"
                  onClick={(e) => {
                    setUpdateInfo({
                      ...updateInfo,
                      address: [...updateInfo.address, ""],
                    });
                    // setUpdateInfo((prevState) => ({
                    //   ...prevState,
                    //   address: {
                    //     ...prevState.address,
                    //     [""]: "",
                    //   },
                    // }));
                  }}
                >
                  <AddIcon />
                </div>
              </div>
              <div className="wrap-block">
                {updateInfo?.address.length > 0 ? (
                  <>
                    {updateInfo?.address.map((item, index) => (
                      <div key={index} className="field flex flex-col">
                        <div className="lbl">Address {index + 1}</div>{" "}
                        <input
                          type="text"
                          name="address"
                          className="txt"
                          value={item}
                          placeholder="Address"
                          onChange={handleAddress(index)}
                        />
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="field flex flex-col">
                    <div className="lbl">Address</div>{" "}
                    <input
                      type="text"
                      name="address"
                      className="txt"
                      // value={item}
                      placeholder="Address"
                      onChange={handleAddress(0)}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="action flex aic justify-end mt-5">
              <div
                className="btn button rounded-lg"
                onClick={(e) => updateGeneralInfo(e)}
              >
                {response?.isLoading ? "Loading..." : "Update"}
              </div>
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default UpdateCompanyInfo;
